import { pick } from 'lodash'
import stores from 'stores'
const { calendarStore, autoScheduleCalendarStore } = stores

const getCellContextMenuProps = (contextProps) => {
  const { calendarType } = contextProps
  const store = getAppropriateStore(calendarType)
  const cellContextMenuProps = pick(contextProps, [
    'calendar',
    'popupProps',
    'onCancel',
    'scrollTop',
    'activeDateRange',
    'unit',
    'unitManager',
    'selection',
    'timeService',
    'hideContextMenu',
    'showCellDetailsPopup',
    'addTimeOffs',
    'changeTimeOffs',
    'changeStaffEventForSelection',
    'createStaffEventsForSelection',
    'deleteSelectedStaffEvents',
    'isCellContextMenuVisible',
    'showWorkingHoursMismatchPopup',
    'setWorkingHoursMismtachDetails',
    'getStaffEventDetails'
  ])
  cellContextMenuProps.calendarStore = store
  return cellContextMenuProps
}

const getCellDetailsProps = (contextProps) => {
  const { calendarType } = contextProps
  const store = getAppropriateStore(calendarType)
  const cellDetailsProps = pick(contextProps, [
    'units',
    'unitId',
    'onCancel',
    'facility',
    'selection',
    'calendar',
    'scrollTop',
    'popupProps',
    'timeService',
    'shiftsMap',
    'unitManager',
    'generalStore',
    'eventVariants',
    'isCellDetailsVisible',
    'addTimeOffs',
    'changeTimeOffs',
    'cellsPathsInProcessing',
    'updateEventForSelection',
    'changeStaffEventForSelection',
    'deleteSelectedStaffEvents',
    'createStaffEventsForSelection',
    'getStaffEventDetails',
    'getViewModel',
    'showWorkingHoursMismatchPopup',
    'setWorkingHoursMismtachDetails'
  ])
  cellDetailsProps.calendarStore = store
  return cellDetailsProps
}
const composeIndicatorsClasses = (indicators) => {
  return {
    '_highlight-annotation': indicators.get('noteOrExplanation'),
    '_highlight-lock': indicators.get('locks'),
    '_highlight-info': indicators.get('info'),
    '_highlight-available': indicators.get('available'),
    '_highlight-unavailable': indicators.get('unavailable')
  }
}

const componseModeClasses = (mode) => {
  return {
    _modeFull: mode === 'full',
    _modeWeek: mode === 'week',
    _modeDay: mode === 'day'
  }
}

const getAppropriateStore = (calendarType) => {
  if (calendarType === 'autoScheduleCalendar') {
    return autoScheduleCalendarStore
  }
  return calendarStore
}

const updateEventParameters = (eventParameters, startTime, endTime, timeService) => {
  eventParameters = eventParameters.set('startTime', startTime)
  const length = timeService.timeMoment(endTime, 'HH:mm').diff(timeService.timeMoment(startTime, 'HH:mm'), 'minutes')
  eventParameters = eventParameters.set('length', length)
  eventParameters = eventParameters.set('range', startTime + ' - ' + endTime)
  return eventParameters
}

const shouldShowWorkingHoursMismatchPopup = (
  shiftStartTime,
  shiftEndTime,
  startTime,
  endTime,
  dateTime,
  timeService
) => {
  const shiftStartAt = timeService.applyTimeToDate(shiftStartTime, dateTime)
  const shiftEndAt = timeService.applyTimeToDate(shiftEndTime, dateTime)
  if (shiftEndAt.isBefore(shiftStartAt)) {
    shiftEndAt.add(1, 'day')
  }
  const eventStartAt = timeService.applyTimeToDate(startTime, dateTime)
  const eventEndAt = timeService.applyTimeToDate(endTime, dateTime)

  return !(eventStartAt.isSameOrBefore(shiftStartAt) && eventEndAt.isSameOrAfter(shiftEndAt))
}

const getDateTimeDetails = (day, timeService) => {
  const dateTime = day.get('dateTime')
  const dateTimeMoment = timeService.timeMoment(dateTime)
  const currentDay = day.get('dayOfWeekShort').toLocaleLowerCase()
  return { dateTime, dateTimeMoment, currentDay }
}

export {
  getCellContextMenuProps,
  getCellDetailsProps,
  composeIndicatorsClasses,
  componseModeClasses,
  getAppropriateStore,
  updateEventParameters,
  shouldShowWorkingHoursMismatchPopup,
  getDateTimeDetails
}
