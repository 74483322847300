import { ComponentController } from 'Common/components'
import { List, Map } from 'immutable'
import memoize from 'memoize-one'
import { CalendarFilterService } from '../../../../services'
import { calculateSortWeightByEmploymentType } from 'services/StaffOrderService'
import { pick } from 'lodash'

export default class ViewController extends ComponentController {
  get defaultState() {
    return { firstVisibleRowIndex: 0, prevVisibleRowIndex: 0 }
  }

  onRowsRendered = ({ startIndex }) => {
    const { firstVisibleRowIndex } = this.state
    const isFirstVisibleRowIndexChanged = firstVisibleRowIndex !== startIndex

    if (isFirstVisibleRowIndexChanged) {
      this.setState({
        firstVisibleRowIndex: startIndex,
        prevVisibleRowIndex: firstVisibleRowIndex
      })
    }
  }

  getFirstVisibleRole(raw) {
    const { firstVisibleRowIndex, prevVisibleRowIndex } = this.state

    let item = this._getItem(raw, firstVisibleRowIndex)
    const kind = item?.get('kind')

    const isStaffDivider = kind === 'staff-divider'
    const isFooter = kind === 'footer'
    const isUnit = kind === 'unit'

    if (isStaffDivider || isFooter || isUnit) {
      item = this._getItem(raw, prevVisibleRowIndex)
    }

    const roleIndex = item?.get('roleIndex')
    if (roleIndex === undefined) {
      return null
    }

    return this.props.getRole(roleIndex)
  }

  getFirstVisibleShift(raw) {
    const { firstVisibleRowIndex } = this.state

    const item = this._getFirstVisibleShiftItem(firstVisibleRowIndex, raw)

    if (!item) {
      return null
    }

    const roleIndex = item.get('roleIndex')
    const shiftIndex = item.get('shiftIndex')

    if (roleIndex === undefined || shiftIndex === undefined) {
      return null
    }

    return this.props.getShift({ roleIndex, shiftIndex }).set('shiftMismatch', item.get('shiftMismatch'))
  }

  _getFirstVisibleShiftItem(index, raw) {
    let item
    // eslint-disable-next-line no-constant-condition
    while (true) {
      item = this._getItem(raw, index)
      if (!item) {
        return null
      }
      const kind = item.get('kind')

      const isStaff = kind === 'staff'
      const isStaffDivider = kind === 'staff-divider'
      const isUnit = kind === 'unit'

      const isFooter = kind === 'footer'
      const isRole = kind === 'role'
      const isShift = kind === 'shift'

      if (isShift) {
        break
      }

      if (isFooter || index < 0) {
        item = null
        break
      }

      if (isStaffDivider || isStaff || isUnit) {
        index--
      }

      if (isRole) {
        index++
      }
    }
    return item
  }

  _getItem = (raw, index) => {
    return raw.get(index % raw.size)
  }

  _getMergeShift = (filters, staffHoursMap, role) => {
    const roleId = role.get('id')
    const shifts = role.get('shifts')
    const isOtherStaff = roleId === 'secondary-staff'
    const roleName = role.get('name')

    const currentView = JSON.parse(filters.get('resourceTypes'))[0]

    let mergeShift = Map({
      id: !isOtherStaff ? `${roleId}-shift` : 'secondary-staff-shift',
      isClass: false,
      unitRoleId: roleId,
      isHidden: isOtherStaff,
      hidden: isOtherStaff,
      isLoaded: false,
      isOtherStaff,
      kind: 'shift',
      name: `${roleName} - Shifts`,
      shiftDay: List()
    })
    const staff = shifts.reduce((memoStaff, shift) => {
      let mergedShiftDays = mergeShift.get('shiftDays')
      if (shift.get('resourceType') !== currentView) {
        return memoStaff
      }
      const shiftDays = shift.get('shiftDays')
      const shiftDay = shift.get('shiftDay')
      if (shiftDay) {
        const mergedShiftDay = mergeShift.get('shiftDay').concat(shiftDay)
        mergeShift = mergeShift.set('shiftDay', mergedShiftDay)
      }
      if (mergedShiftDays) {
        mergedShiftDays = mergedShiftDays.map((shiftDay, idx) => {
          const existingShiftDay = shiftDays.get(idx)
          if (!existingShiftDay) {
            return shiftDay
          }
          return Map({
            staffMismatch: shiftDay.get('staffMismatch') + existingShiftDay.get('staffMismatch'),
            startsAt: shiftDay.get('startsAt'),
            coverage: shiftDay.get('coverage') + existingShiftDay.get('coverage'),
            minimumCoverageRequired:
              shiftDay.get('minimumCoverageRequired') + existingShiftDay.get('minimumCoverageRequired'),
            maximumCoverageRequired:
              shiftDay.get('maximumCoverageRequired') + existingShiftDay.get('maximumCoverageRequired')
          })
        })
        mergeShift = mergeShift.set('shiftDays', mergedShiftDays)
      } else {
        mergeShift = mergeShift.set(
          'shiftDays',
          List(
            shiftDays.map((shiftDay) =>
              Map({
                staffMismatch: shiftDay.get('staffMismatch'),
                startsAt: shiftDay.get('startsAt'),
                coverage: shiftDay.get('coverage'),
                minimumCoverageRequired: shiftDay.get('minimumCoverageRequired'),
                maximumCoverageRequired: shiftDay.get('maximumCoverageRequired')
              })
            )
          )
        )
      }
      const { shiftStaff, shiftMismatch } = this._getShiftStaff(shift, staffHoursMap, filters)
      mergeShift = this._updateShiftMismatch(shiftMismatch, mergeShift, shiftStaff)
      return memoStaff.concat(shiftStaff)
    }, List())
    return mergeShift.concat(staff)
  }

  _getShiftStaff = (shift, staffHoursMap, filters) => {
    const { isStaffVisible } = this._getFilters(filters)
    const isShiftLoading = !shift.get('isLoaded')
    const shiftName = shift.get('name')
    const shiftId = shift.get('id')
    const roleId = shift.get('unitRoleId')
    const groupBy = filters.getIn(['displayOptions', 'staff', 'groupBy'])
    let shiftMismatch = Array(24).fill(0)
    let updatedShift = shift
      .get('staff')
      .sort(this._sortShiftStaff)
      .map(this._countEventHours)
      .map(this._addWeekHours(shift))
      .reduce((memoStaff, staff) => {
        if (!isStaffVisible(staff)) {
          return memoStaff
        }
        if (groupBy === 'employment' && memoStaff.size && staff.get('type') !== memoStaff.last().get('type')) {
          const key = `st-${shiftId}-${memoStaff.last().get('type')}-${staff.get('type')}`
          memoStaff = memoStaff.push(Map({ kind: 'staff-divider', key }))
        }

        const staffId = staff.get('id')
        const hrs = this._fixedHours(staffHoursMap.getIn([staffId, 'value']))

        return memoStaff.push(staff.merge({ roleId, shiftId, isShiftLoading, shiftName, hrs }))
      }, List())

    if (shiftMismatch && updatedShift) {
      updatedShift.forEach((item) => {
        const hourCells = item.get('hourCells')
        if (hourCells) {
          shiftMismatch = hourCells.map((misItem, index) => this._getMisItemValue(misItem) + shiftMismatch[index])
        }
      })
    }
    return { shiftStaff: updatedShift, shiftMismatch }
  }

  _updateShiftMismatch = (shiftMismatch, mergeShift, shiftStaff) => {
    const mergeShiftStaff = mergeShift.get('staff', List())
    let updatedMergeShift = mergeShift.set('staff', mergeShiftStaff.concat(shiftStaff))
    if (shiftMismatch) {
      const currentShiftMismatch = updatedMergeShift.get('shiftMismatch')
      if (currentShiftMismatch) {
        const mergedShiftMismatch = currentShiftMismatch.map((num, idx) => {
          return num + shiftMismatch[idx]
        })
        updatedMergeShift = updatedMergeShift.set('shiftMismatch', mergedShiftMismatch)
      } else {
        updatedMergeShift = updatedMergeShift.set('shiftMismatch', shiftMismatch)
      }
    }
    return updatedMergeShift
  }

  _getFilters = memoize((filters) => {
    const calendarFilterService = new CalendarFilterService(filters)
    const { isRoleVisible, isShiftVisible, isStaffVisible } = calendarFilterService

    return { isRoleVisible, isShiftVisible, isStaffVisible }
  })

  _sortShiftStaff = (staff1, staff2) => {
    const { filters } = this.props

    const displayOptions = filters.get('displayOptions')
    const groupBy = displayOptions.getIn(['staff', 'groupBy'])
    const sortBy = displayOptions.getIn(['staff', 'sortBy'])
    const orderBy = displayOptions.getIn(['staff', 'orderBy'])
    const direction = orderBy === 'asc' ? 1 : -1
    const isGroupByEmployment = groupBy === 'employment'

    if (isGroupByEmployment) {
      const sortWeight1 = calculateSortWeightByEmploymentType(staff1)
      const sortWeight2 = calculateSortWeightByEmploymentType(staff2)

      if (sortWeight1 !== sortWeight2) {
        return sortWeight1 > sortWeight2 ? 1 : -1
      }
    }
    const sort = this.getSortValue(sortBy, staff1, staff2)

    return sort * direction
  }

  _countEventHours = (staff) => {
    const { activeDateRange, usDate } = this.props

    const currentDayIndex = activeDateRange.get('days', []).findIndex((day) => day.usDate === usDate)
    let hourCells = Array.from(Array(24).keys()).map((index) => ({
      value: 0,
      hour: index,
      dayIndex: currentDayIndex
    }))
    let eventBars = []
    const staffEvent = staff.get('staffEvent')
    if (this._isStaffEvent(staffEvent)) {
      const { updatedEventBars, updatedHourCells } = this._handleStaffEvent(
        staffEvent,
        currentDayIndex,
        usDate,
        hourCells,
        staff,
        eventBars
      )
      hourCells = updatedHourCells
      eventBars = updatedEventBars
    }
    staff = staff.set('hourCells', hourCells)
    staff = staff.set('eventBars', eventBars)
    return staff
  }

  _addWeekHours(shift) {
    const { viewMode = 'full' } = this.props
    const staffAssignedWeekHours = shift.get('staffAssignedWeekHours') && shift.get('staffAssignedWeekHours').toJS()
    return (staff, staffIndex) => {
      if (viewMode === 'day') {
        const userId = staff.get('id')
        const staffAssignedWeekHoursByUserId = staffAssignedWeekHours?.filter((item) => item['userId'] === userId)
        const weekHoursObject = staffAssignedWeekHoursByUserId?.[0]
        const weekHours = weekHoursObject?.('assignedHours')
        staff = staff.set('weekHours', weekHours)
      }
      return staff
    }
  }

  getSortValue = (sortBy, staff1, staff2) => {
    const { timeService } = this.props
    let sort
    const firstName1 = staff1.get('firstName')
    const lastName1 = staff1.get('lastName')
    const hireDate1 = staff1.getIn(['staffProfile', 'startsAt'])

    const firstName2 = staff2.get('firstName')
    const lastName2 = staff2.get('lastName')
    const hireDate2 = staff2.getIn(['staffProfile', 'startsAt'])
    switch (true) {
      case sortBy === 'firstName':
        sort = firstName1.toLowerCase() > firstName2.toLowerCase() ? 1 : -1
        break

      case sortBy === 'lastName':
        sort = lastName1.toLowerCase() > lastName2.toLowerCase() ? 1 : -1
        break

      case sortBy === 'hireDate':
        if (!hireDate1) {
          sort = -1
        } else if (!hireDate2) {
          sort = 1
        } else {
          sort = timeService.timeMoment(hireDate1).isBefore(hireDate2) ? 1 : -1
        }
        break

      default:
        sort = 0
    }
    return sort
  }

  _fixedHours(hrs = 0) {
    const numericHrs = Number(hrs)
    if (isNaN(numericHrs) || Math.floor(numericHrs) !== numericHrs) {
      return '0.00'
    }
    return numericHrs.toFixed(2)
  }

  _isStaffEvent = (staffEvent) => {
    return staffEvent && staffEvent.length > 0
  }

  _getMisItemValue = (misItem) => {
    const { value, eventType } = misItem
    return value <= 1 && (eventType === 'assignment' || eventType === 'onCall') ? value : 0
  }

  _handleStaffEvent = (staffEvent, currentDayIndex, usDate, hourCells, staff, eventBars) => {
    let tempHourCells = hourCells
    const { activeDateRange, timeService } = this.props
    for (const eventStaff of staffEvent) {
      this.isEventStaff(eventStaff)
      let eventDuration = eventStaff?.duration
      const startsAt = eventStaff?.startsAt
      const eventType = eventStaff?.type
      const usStartAtDate = timeService.timeMoment(timeService.timeMoment(startsAt)).format('MM-DD-YYYY')
      const dayIndex = activeDateRange.get('days', []).findIndex((day) => day.usDate === usStartAtDate)
      if (this._isDayIndex(currentDayIndex, dayIndex)) {
        continue
      }

      if (eventType === 'timeOff') {
        const timeOffAttributes = eventStaff?.timeOffAttributes
        const paidMinutes = timeOffAttributes?.paidMinutes
        if (!paidMinutes) {
          tempHourCells = Array.from(Array(24).keys()).map((index) => ({
            value: 1,
            hour: index,
            dayIndex,
            eventType
          }))
          eventBars.push({
            ...eventStaff,
            startsAtHours: 0,
            endsAtHours: 23,
            firstHour: 1,
            lastHour: 1,
            dayIndex
          })
          continue
        } else {
          eventDuration = paidMinutes
        }
      }
      if (this._isStartAtAndEventDuration(startsAt, eventDuration)) {
        const dayDate = timeService.timeMoment(timeService.timeMoment(startsAt)).format('MM-DD-YYYY')
        const startsAtMinutes = timeService.timeMoment(startsAt).minutes()

        const { hourDiff, startsAtHours } = this._hourDiffAndstartsAtHours(startsAt, dayDate, usDate)
        const firstHour = 1 - 1 / (60 / startsAtMinutes)
        const eventDurationHours = eventDuration / 60

        tempHourCells[startsAtHours] = {
          value: firstHour,
          hour: startsAtHours,
          dayIndex,
          eventType
        }

        const fullHours = Math.trunc(eventDurationHours - firstHour - hourDiff)

        const shiftHours = Array(fullHours)
          .fill(0)
          .map((_, idx) => startsAtHours + idx + 1)
          .filter((hour) => hour <= 23)
        this._calculateHourCellsHour(tempHourCells, shiftHours, dayIndex, eventType)
        const lastHour = (eventDurationHours - firstHour) % 1

        this._calculateHourCells(tempHourCells, lastHour, startsAtHours, fullHours, dayIndex, eventType)
        tempHourCells = this._hourCells(tempHourCells)
        eventBars.push({
          ...eventStaff,
          startsDayBefore: dayIndex === currentDayIndex - 1,
          startsAtHours,
          endsAtHours: startsAtHours + fullHours + 1,
          firstHour,
          lastHour,
          dayIndex
        })
        staff = staff.set('firstHour', startsAtHours).set('lastHour', startsAtHours + fullHours)
      }
    }
    return { updatedEventBars: eventBars, updatedHourCells: tempHourCells }
  }

  isEventStaff = (eventStaff) => {
    if (!eventStaff) {
      return
    }
  }

  _isDayIndex = (currentDayIndex, dayIndex) => {
    return currentDayIndex - 1 !== dayIndex && currentDayIndex !== dayIndex
  }

  _isStartAtAndEventDuration = (startsAt, eventDuration) => {
    return startsAt && eventDuration
  }

  _hourDiffAndstartsAtHours = (startsAt, dayDate, usDate) => {
    const { timeService } = this.props
    let hourDiff = 0
    let startsAtHours = timeService.timeMoment(startsAt).hours()
    if (dayDate !== usDate) {
      hourDiff = 24 - startsAtHours
      startsAtHours = 0
    }
    return { hourDiff, startsAtHours }
  }

  _calculateHourCellsHour = (hourCells, shiftHours, dayIndex, eventType) => {
    for (const hour of shiftHours) {
      hourCells[hour] = {
        value: 1,
        hour,
        dayIndex,
        eventType
      }
    }
  }

  _calculateHourCells = (hourCells, lastHour, startsAtHours, fullHours, dayIndex, eventType) => {
    if (lastHour && startsAtHours + fullHours + 1 <= 23) {
      hourCells[startsAtHours + fullHours + 1] = {
        value: lastHour,
        hour: startsAtHours + fullHours,
        dayIndex,
        eventType
      }
    }
  }

  _hourCells = (hourCells) => {
    return hourCells.map((cell) => (cell.value === 0 ? pick(cell, ['value', 'hour', 'paidMinutes']) : cell))
  }
}
