import { PureComponent } from 'react'
import classNames from 'classnames'
import { OpenShiftEntity } from 'entityWrappers'
import Avatar from 'Manager/components/Avatar'

export default class OpenShiftActivity extends PureComponent {
  render() {
    const { openShift } = this.props
    const openShiftEntity = new OpenShiftEntity(openShift)
    const { pendingToConfirmUsers, approvedFacilityUsers, availableFacilityUsers, isCompleted, isPosted } =
      openShiftEntity

    const users = isCompleted ? approvedFacilityUsers : pendingToConfirmUsers
    const usersCount = users.size
    const isNoResponse = isPosted && usersCount === 0
    const noAvailableStaffRemains = availableFacilityUsers.size === 0
    const requireAdditionalStaff = isPosted && noAvailableStaffRemains && !isCompleted
    const className = classNames('semibold trout hx-open-shift-item-staff col-3', {
      'left-exclamation': requireAdditionalStaff
    })

    return (
      <div className={className} data-testid="open-shift-activity">
        {isNoResponse && <NoResponse />}
        {usersCount > 0 && <Staff users={users} />}
      </div>
    )
  }
}

function NoResponse() {
  return <span className="half-opacity">0 accepted</span>
}

function Staff({ users }) {
  const profile = users.size === 1 && users.getIn([0, 'profile'])
  const description = `${users.size} accepted`

  return (
    <>
      {users.slice(0, 4).map((user) => (
        <Avatar
          key={user.get('userId')}
          userId={user.get('userId')}
          profile={user.get('profile')}
          size={24}
          avatarClass="rounded"
          initialsClass="bg-periwinklegray rounded-12 hx-default-avatar"
        />
      ))}
      {profile && <span>{`${profile.get('firstName')} ${profile.get('lastName')} accepted`}</span>}
      {users.size > 1 && <span>{description}</span>}
    </>
  )
}
