import { fromJS } from 'immutable'
import { OpenShiftsService } from 'services'
import makeSummary from '../makeSummary'
import buildOpenShiftsMap from '../buildOpenShiftsMap'
import { getConfig } from '../OpenShiftsConfig'
import { openShiftsQuery, shiftOpportunitiesQuery } from '../../Queries'

function LoadOpenShifts() {
  let gqlClient, timeService, updateOpenShiftsStateWith
  const actions = { loadOpenShifts }

  return { actions, initialize }

  function initialize(context) {
    ;({ gqlClient, facilityTime: timeService, updateOpenShiftsStateWith } = context)
  }

  async function loadOpenShifts({ unit, startDate, endDate, facilityUsersMap }) {
    const unitId = unit.get('id')
    const shifts = unit.get('shifts')
    const shiftsById = shifts.groupBy((shift) => shift.get('id'))
    const parameters = { unitId, startDate, endDate, limit: 9999999 }
    const { shiftOpportunities: shiftOpportunitiesRaw } = await gqlClient.query(shiftOpportunitiesQuery, parameters)
    const { openShifts: openShiftsRaw } = await gqlClient.query(openShiftsQuery, parameters)
    const roles = unit.get('roles')
    const shiftOpportunities = fromJS([...shiftOpportunitiesRaw, ...openShiftsRaw])
    const shiftOpportunitiesByRoleId = shiftOpportunities.groupBy((shift) => shift.get('unitRoleId'))
    const filteredRoles = roles.filter((role) => shiftOpportunitiesByRoleId.has(role.get('id')))
    const openShiftsService = new OpenShiftsService(timeService)
    const openShiftsExtended = openShiftsService.extendOpenShifts(
      shiftsById,
      shiftOpportunities,
      {
        facilityUsersMap
      },
      roles
    )

    return updateOpenShiftsStateWith((openShiftsState) => {
      const config = getConfig(openShiftsState)

      const openShiftsMap = buildOpenShiftsMap(openShiftsExtended, config, timeService, filteredRoles)
      const summary = makeSummary(shiftOpportunities)

      return openShiftsState.merge({
        summary,
        openShiftsMap,
        openShifts: openShiftsExtended
      })
    })
  }
}

export default LoadOpenShifts()
