import { WeScanAppointmentState } from 'stores/remoteWorkflowStore/enums/WeScanAppointmentState'
import { IRemoteStaffRequest, IWeScanAppointment } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'
import { ImmutableMapType } from 'types/ImmutableType'

export const getAllotedAndTotalRemoteStaff = (remoteStaffRequest: ImmutableMapType<IRemoteStaffRequest>) => {
  let allotedStaffCount = 0
  let totalNumberOfStaffNeedsToBeAlloted = 0
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (remoteStaffRequest.get('remoteWorkflowType')) {
    case 'wescan': {
      const allAppointments = remoteStaffRequest.getIn(['requestDetails', 'wescan', 'appointments'])
      allotedStaffCount = allAppointments.reduce(
        (totalAppointments: number, appointment: ImmutableMapType<IWeScanAppointment>) => {
          return [WeScanAppointmentState.Assigned, WeScanAppointmentState.Closed].includes(appointment.get('state'))
            ? totalAppointments + 1
            : totalAppointments
        },
        0
      )
      totalNumberOfStaffNeedsToBeAlloted = allAppointments.size
      break
    }
    default:
      allotedStaffCount = 0
      totalNumberOfStaffNeedsToBeAlloted = 0
  }
  return { allotedStaffCount, totalNumberOfStaffNeedsToBeAlloted }
}
