import { curry } from 'i18n'
import { Fragment } from 'react'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'
import { getAllotedAndTotalRemoteStaff } from 'utils/getAllotedAndTotalRemoteStaff'
import getClassNameForRemoteRequest from 'utils/getClassNameForRemoteRequest'
import { TimeService } from 'services'
import { ImmutableMapType } from 'types/ImmutableType'
const t = curry('openShifts.cards.')

interface IRemoteStaffRequestsDetailsProps {
  remoteStaffRequests: ImmutableMapType<IRemoteStaffRequest>[]
  timeService: TimeService
}

const RemoteStaffRequestDetailsRows = ({ remoteStaffRequests, timeService }: IRemoteStaffRequestsDetailsProps) => {
  return <>
    {
      remoteStaffRequests.map((remoteStaffRequest) => {
        const remoteStaffRequestState = remoteStaffRequest.get('state')
        const { allotedStaffCount, totalNumberOfStaffNeedsToBeAlloted } = getAllotedAndTotalRemoteStaff(remoteStaffRequest)
        const classForRemoteStaffRequestState = getClassNameForRemoteRequest(remoteStaffRequestState)
        const createdAtDateTime = timeService.timeMoment(remoteStaffRequest.get('createdAt')).format('MMM D, h:mm')
        const assignedStatus = `${allotedStaffCount}/${totalNumberOfStaffNeedsToBeAlloted} assigned`

        const requestId = remoteStaffRequest.get('id')

        return (
          <Fragment key={requestId}>
            <div className="trout semibold col-3"></div>
            <div className="col-2"></div>
            <div className="col-3"></div>
            <div className="semibold col-1"></div>
            <div className="semibold col-2">{t('requestType.weScan')}</div>
            <div className={`upper fs-10 black col-3 ${classForRemoteStaffRequestState}`}>{remoteStaffRequestState}</div>
            <div className="semibold trout hx-open-shift-item-staff col-3">{assignedStatus}</div>
            <div className="semibold col-1">{createdAtDateTime}</div>
          </Fragment>
        )
      })
    }</>
}

export default RemoteStaffRequestDetailsRows
