import classNames from 'classnames'
import { t } from 'i18n'
import StaffRow from './StaffRow'
import './StaffSelector.scss'
import { useState } from 'react'
import { CalendarFilterService } from 'services'

const StaffSelector = ({ currentStaff, staffList, onSelect }) => {
  const [staffFilter, setStaffFilter] = useState('')
  const isStaffSelected = !!currentStaff
  const filteredStaffList = staffList.filter((staff) =>
    CalendarFilterService.filterUserBySearch(staff, staffFilter.toLowerCase().trim())
  )
  const staffRows =
    filteredStaffList.length === 0 ? (
      <div>{t('calendar.no_staff_found_message')}</div>
    ) : (
      <div className={`staffBox ${classNames({ hideStaffBox: isStaffSelected })}`}>
        {filteredStaffList.map((staff) => (
          <StaffRow key={staff.get('id')} staff={staff} handleStaffSelection={() => onSelect(staff)} />
        ))}
      </div>
    )

  return (
    <div className="order3">
      <div className="row">
        <div className="form-item col col-3 text-right pr10">
          <label>{t('calendar.staff')}</label>
        </div>
        {!isStaffSelected && (
          <div className="form-item col col-9">
            <input
              type="text"
              placeholder={t('calendar.search_staff')}
              onChange={(e) => {
                setStaffFilter(e.currentTarget.value)
              }}
            />
          </div>
        )}
      </div>
      <div className="row pt5">
        <div className="form-item col col-3" />
        <div className="form-item col col-9">
          {staffList.length === 0 ? <div>{t('calendar.no_staff_for_unit_message')}</div> : staffRows}
          {isStaffSelected && (
            <div className={`staffBox ${classNames({ selectedStaff: isStaffSelected })}`}>
              <StaffRow
                staff={currentStaff}
                removeStaffSelection={() => onSelect(null)}
                isStaffSelected={isStaffSelected}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StaffSelector
