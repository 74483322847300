import { Component } from 'react'
import classNames from 'classnames'
import './WorkingHours.scss'
import { merge } from 'lodash'
// @ts-ignore
import StringInputField from '@humanics/he-react-common/lib/components/Form/StringInputField'
import moment from 'moment'
import { curry } from 'i18n'

const t = curry('staff.')

interface IOperationalHoursProps {
  onChange: (value: any, field: string) => void
  field?: string | object
  value?: any
  defaultValues?: any
  definition?: any
  setFormData?: (data: any) => void
  formData?: any
  model?: string
  isEditable?: boolean
  isWorking?: boolean
  error?: string
  formItemClasses?: string
}

export default class OperationalHours extends Component<IOperationalHoursProps> {
  componentDidMount() {
    this.updateValue()
  }

  componentDidUpdate(prevProps: IOperationalHoursProps) {
    const { value, formData } = this.props

    if (prevProps.value !== value) {
      this.setFormData(value)
    }

    if (prevProps.formData !== formData) {
      this.updateValue()
    }
  }

  updateValue() {
    const { field, value, defaultValues, onChange, formData, isEditable, isWorking } = this.props

    if (!isWorking) return

    const updatedValue =
      formData && formData.defaultDaySettings && !isEditable
        ? merge({}, value, formData.defaultDaySettings, defaultValues)
        : merge({}, value, defaultValues)

    if (!formData.defaultDaySettings && !value) {
      onChange(null, field as string)
    } else {
      onChange(updatedValue, field as string)
    }
  }

  setFormData(selectedValue: any) {
    const { setFormData, model } = this.props
    if (model && selectedValue) {
      setFormData?.({ [model]: selectedValue })
    }
  }

  updateTimeValue = (value: any, field: string, newValue: any) => {
    const updatedValue = { ...value, [field]: newValue.target.value }
    if (field === 'startTime' || field === 'endTime') {
      const { startTime: newStartTime, endTime: newEndTime } = updatedValue
      if (newStartTime && newEndTime && moment(newEndTime, 'HH:mm').isBefore(moment(newStartTime, 'HH:mm'))) {
        updatedValue.endTime = newStartTime
      }
    }
    return updatedValue
  }

  renderCustomInputTime = (key: string, value: any, field: string, definition: any) => {
    const { onChange, error, field: inputField, isEditable } = this.props
    const { [field]: itemValue = '', startTime = null, endTime = null } = value || {}
    const duration = startTime && endTime ? this.calculateDuration(startTime, endTime) : '0:00'

    const handleTimeChange = (newValue: any) => {
      const updatedValue = this.updateTimeValue(value, field, newValue)
      onChange(updatedValue, inputField as string)
    }

    definition.required =
      (field === 'endTime' && startTime && !endTime) || (field === 'startTime' && endTime && !startTime)
  

    definition.disabled = isEditable === false || definition.isDisabled
    const inputProps = {
      key,
      formItemClasses: 'w50',
      field,
      definition,
      value: itemValue,
      onChange: handleTimeChange,
      error: !itemValue ? error : undefined
    }
    const durationDefinition = {
      value: duration,
      label: 'duration',
      type: 'text',
      default: '0:00',
      disabled: true
    }
    const durationInputProps = {
      key: `${key}-duration`,
      definition: durationDefinition
    }
    return (
      <>
        <StringInputField {...inputProps} />
        {field === 'endTime' && <StringInputField {...durationInputProps} />}
      </>
    )
  }

  renderDropdowns = (value: any) => {
    const { definition } = this.props
    const { items } = definition

    return Object.keys(items).map((field, key) => {
      const itemDefinition = items[field]
      const { inputType } = itemDefinition

      if (inputType === 'InputTime') {
        return this.renderCustomInputTime(key.toString(), value, field, itemDefinition)
      }

      return null
    })
  }

  calculateDuration = (startTime: string, endTime: string) => {
    const start = moment(startTime, 'HH:mm')
    const end = moment(endTime, 'HH:mm')
    const duration = moment.duration(end.diff(start))
    const hours = Math.floor(duration.asHours())
    const minutes = duration.minutes()
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
  }

  render() {
    const { formItemClasses, definition, value, field } = this.props
    const { label } = definition
    return (
      <>
        {field === 'defaultDetails' && (
          <div className="flex mt10">
            <label>
              <span className="form-label flex w100">{label}</span>
            </label>
            <label>
              <span className="form-label flex offset-5 pl20 w100">{t('staffWorkingHours.hoursOfOperation')}</span>
            </label>
          </div>
        )}
        <div className={classNames(formItemClasses)}>
          <div className="form-input">{this.renderDropdowns(value)}</div>
        </div>
      </>
    )
  }
}
