import { PureComponent } from 'react'
import { ImmutableSelect } from 'Manager/components'
import { memoize, pick } from 'lodash'
import StaffEventController from './StaffEventController'
import Notes from '../Notes'
import NotesController from './StaffEventNotesController'
import StaffEventTemplateSelector from './StaffEventTemplateSelector'
import classNames from 'classnames'
import { hasShiftEvent } from '../cellDetailsUtils'
import { shouldShowWorkingHoursMismatchPopup, getDateTimeDetails } from 'Manager/Calendar/layoutUtils'
import { List, Map } from 'immutable'
import StaffSelector from './StaffSelector'
import { t } from 'i18n'
import { StaffWorkingHoursService, ShiftService } from 'services'

const resourceTypeLabels = {
  shift: t('calendar.shift'),
  equipment: t('calendar.equipment'),
  subspeciality: t('calendar.subspeciality')
}

export default class ShiftForm extends PureComponent {
  constructor(props) {
    super(props)

    this.notesController = new NotesController(this)
    this.staffEventController = new StaffEventController(this)

    const { defaultState: staffEventState } = this.staffEventController
    const { defaultState: notesState } = this.notesController

    this.state = { ...staffEventState, ...notesState, selectedStaff: null, selectedResourceType: null }
  }

  async componentDidMount() {
    const { cell, staff } = this.props
    this.notesController.loadNotes()
    await this.staffEventController.loadCellStaffEvents()
    if (cell.get('isAvatarCell') && cell.get('staff')) {
      const staff = this.getStaff(cell.get('staff'))
      const unitId = staff.getIn(['staffProfile', 'eligibleUnits'])?.get(0)?.get('homeUnitId')
      this.setState({ selectedStaff: staff, unitId })
    }

    if (staff.get('kind') === 'staff') {
      this.setState({ selectedStaff: staff })
    }
  }

  async componentDidUpdate(prevProps) {
    const { cell, staff } = this.props
    const { cell: prevCell } = prevProps

    const isCellChanged = cell !== prevCell
    if (isCellChanged) {
      this.notesController.loadNotes()
      await this.staffEventController.loadCellStaffEvents()
    }
    if (!this.state.selectedResourceType) {
      const resourceTypes = this.getResourceTypesFromEventTemplates()
      resourceTypes.get(0)?.get('id') && this.onResourceTypeChange(resourceTypes.get(0)?.get('id'))
    }

    if (staff.get('kind') === 'staff') {
      this.setState({ selectedStaff: staff })
    }
  }

  render() {
    // add support of cellSubIndex
    const { selectedParameters, eligibleUnits } = this.staffEventController
    if (!eligibleUnits || eligibleUnits.size === 0) {
      return null
    }
    const { cell, isProcessing, onCancel, isShiftFormShown } = this.props
    const { isNotesLoading, isStaffEventLoading } = this.state
    const isLoading = isNotesLoading || isStaffEventLoading
    const { unitId, eventTemplate } = selectedParameters

    const hasEvent = hasShiftEvent(cell)

    const isFormFilled = !!unitId && !!eventTemplate
    const isInputDisabled = isLoading || isProcessing
    const isActionDisabled =
      isLoading || isProcessing || !isFormFilled || (cell.get('isAvatarCell') && !this.state.selectedStaff)

    const className = classNames('add-shift bt1', { hide: !isShiftFormShown })

    const shiftFormTitle = hasEvent ? t('calendar.update_assignment_label') : t('calendar.assign_staff_label')

    return (
      <div className={className}>
        {cell.get('isAvatarCell') && <header className="p20 pb0 bold">{shiftFormTitle}</header>}
        {isLoading && this.renderSpinner()}
        {!isLoading && this.renderForm()}

        <footer className="p20">
          <button className="button compact secondary borderless ph0" onClick={onCancel} disabled={isInputDisabled}>
            Cancel
          </button>

          {hasEvent && (
            <>
              <button
                disabled={isActionDisabled}
                className="button float-right compact bg-skyblue _update-button"
                onClick={this.update}
              >
                Update
              </button>

              <span className="float-right">&nbsp;</span>

              <button
                className="button float-right compact primary _delete-button"
                onClick={this.delete}
                disabled={isInputDisabled}
              >
                Remove
              </button>
            </>
          )}

          {!hasEvent && (
            <button
              disabled={isActionDisabled}
              className="button float-right compact bg-skyblue _create-button"
              onClick={this.create}
            >
              Add
            </button>
          )}
        </footer>
      </div>
    )
  }

  renderSpinner() {
    return (
      <section className="p15">
        <div className="loader">
          <i className="loading spindle" />
        </div>
      </section>
    )
  }

  renderForm() {
    const { eligibleUnits, eventTemplates, selectedParameters, selectedEventTemplateId } = this.staffEventController
    const { unitId, eventTemplate } = selectedParameters
    const { cell, shiftsMap } = this.props

    const unitSelectProps = {
      disabled: !unitId,
      options: eligibleUnits,
      value: unitId,
      onChange: this.onUnitChange
    }

    const resourceTypes = this.getResourceTypesFromEventTemplates()

    const filteredEventTemplates = this.getEventTemplatesForResourceType(
      eventTemplates,
      this.state.selectedResourceType,
      shiftsMap
    )

    const resourceTypeSelectProps = {
      options: resourceTypes,
      value: this.state.selectedResourceType || '',
      onChange: (e) => {
        this.onResourceTypeChange(e.currentTarget.value)
      }
    }

    const eventTemplateSelectorProps = pick(
      {
        ...this.props,
        eventTemplates: filteredEventTemplates,
        eventTemplate,
        setEventTemplate: this.staffEventController.setEventTemplate,
        selectedEventTemplateId,
        loadNotes: () => this.notesController.loadNotes(),
        selectedStaff: this.state.selectedStaff
      },
      [
        'timeService',
        'day',
        'eventTemplates',
        'eventTemplate',
        'shiftsMap',
        'setEventTemplate',
        'selectedEventTemplateId',
        'loadNotes',
        'cell',
        'shiftId',
        'selectedStaff',
        'staff'
      ]
    )

    const { notes } = this.notesController
    const notesProps = { notes, notesController: this.notesController }

    const hasEvent = hasShiftEvent(cell)

    const unitStaffList = this.getUnitStaffList(unitId)
    return (
      <>
        <section className="p15 pt0 flex-column">
          <div className="row pt20 order2">
            <div className="form-item col col-3 text-right pr10">
              <label>{t('calendar.unit')}</label>
            </div>
            <div className="form-item col col-9">
              <ImmutableSelect {...unitSelectProps} disabled={!cell.get('isAvatarCell')} />
            </div>
          </div>
          {!cell.get('isAvatarCell') && (
            <div className="row">
              <div className="form-item col col-3 text-right pr10">
                <label>{t('calendar.resource_type')}</label>
              </div>
              <div className="form-item col col-9">
                <ImmutableSelect {...resourceTypeSelectProps} disabled={cell.get('isAvatarCell')} />
              </div>
            </div>
          )}
          <StaffEventTemplateSelector {...eventTemplateSelectorProps} hasEvent={hasEvent} />
          {cell.get('isAvatarCell') && (
            <StaffSelector
              currentStaff={this.state.selectedStaff}
              staffList={unitStaffList}
              onSelect={(staff) => {
                this.setState({ selectedStaff: staff })
              }}
            />
          )}
        </section>

        <Notes {...notesProps} />
      </>
    )
  }

  getEventTemplatesForResourceType = memoize((eventTemplates, resourceType, shiftsMap) => {
    return eventTemplates.filter(
      (e) => !resourceType || !e.shiftId || shiftsMap.get(e.shiftId)?.get('resourceType') === resourceType
    )
  })

  onUnitChange = (e) => {
    const unitId = e.currentTarget.value
    this.staffEventController.unitId = unitId
    this.setState({ selectedStaff: null })
  }

  onResourceTypeChange = (resourceType) => {
    const { shiftId, cell } = this.props
    const { selectedParameters, eventTemplates, setEventTemplate } = this.staffEventController
    const eventTemplatesToSearch = hasShiftEvent(cell) ? List(selectedParameters.eventTemplate) : eventTemplates
    const filteredEventTemplates = this.getEventTemplatesForResourceType(
      eventTemplatesToSearch,
      resourceType,
      this.props.shiftsMap
    )
    this.setState({ selectedResourceType: resourceType })

    const defaultEventTemplate =
      filteredEventTemplates.find((e) => e.shiftId === shiftId) || filteredEventTemplates.get(0)
    setEventTemplate(defaultEventTemplate?.id)
  }

  create = async () => {
    const { day, showWorkingHoursMismatchPopup, setWorkingHoursMismtachDetails, shiftsMap, timeService, onCancel } =
      this.props

    const staffService = new StaffWorkingHoursService(this.state.selectedStaff)

    const { dateTime, currentDay } = getDateTimeDetails(day, timeService)
    const { startTime, endTime } = staffService.getWorkingHoursPeriodForDay(currentDay)

    const eventParamaters = this.staffEventController.getEventParameters()
    const selectedShiftId = eventParamaters.get('shiftId')
    const shift = shiftsMap.get(selectedShiftId)

    const shiftService = new ShiftService(shift, timeService)
    const { shiftStartTime, shiftEndTime } = shiftService.getShiftStartEndTime()

    if (
      startTime &&
      endTime &&
      shouldShowWorkingHoursMismatchPopup(shiftStartTime, shiftEndTime, startTime, endTime, dateTime, timeService) &&
      startTime !== eventParamaters.get('startsAt') &&
      endTime !== eventParamaters.get('endsAt')
    ) {
      setWorkingHoursMismtachDetails(this.handleCreate)
      showWorkingHoursMismatchPopup()
      return
    }
    this.handleCreate()
    onCancel()
  }

  handleCreate = () => {
    const { createStaffEventsForSelection, getStaffEventDetails, cell } = this.props
    let eventParameters = this.staffEventController.getEventParameters()
    if (cell.get('isAvatarCell')) {
      eventParameters = eventParameters.set('addStaffToOnCallShift', true).set('staff', this.state.selectedStaff)
    }
    const { note } = this.notesController
    getStaffEventDetails?.(eventParameters)

    createStaffEventsForSelection(eventParameters, note)
  }

  update = async () => {
    const { day, setWorkingHoursMismtachDetails, showWorkingHoursMismatchPopup, shiftsMap, timeService } = this.props
    const staffService = new StaffWorkingHoursService(this.state.selectedStaff)

    const { dateTime, currentDay } = getDateTimeDetails(day, timeService)

    const { startTime, endTime } = staffService.getWorkingHoursPeriodForDay(currentDay)

    const eventParamaters = this.staffEventController.getEventParameters()
    const selectedShiftId = eventParamaters.get('shiftId')
    const shift = shiftsMap.get(selectedShiftId)

    const shiftService = new ShiftService(shift, timeService)
    const { shiftStartTime, shiftEndTime } = shiftService.getShiftStartEndTime()

    if (
      startTime &&
      endTime &&
      shouldShowWorkingHoursMismatchPopup(shiftStartTime, shiftEndTime, startTime, endTime, dateTime, timeService) &&
      startTime !== eventParamaters.get('startsAt') &&
      endTime !== eventParamaters.get('endsAt')
    ) {
      setWorkingHoursMismtachDetails(this.handleUpdate)
      showWorkingHoursMismatchPopup()
      return
    }
    this.handleUpdate()
  }

  handleUpdate = () => {
    const { onCancel, cell } = this.props
    const { staffEvent, selectedStaff } = this.state
    const selectedEventParameters = this.staffEventController.getEventParameters()
    const selectedShiftId = selectedEventParameters.get('shiftId')
    const isShiftChanged = staffEvent.some((item) => item.shiftId === selectedShiftId)
    const isStaffChanged = selectedStaff && selectedStaff.get('id') !== cell.get('staff')
    isStaffChanged || !isShiftChanged ? this.changeEvent() : this.updateEvent()
    onCancel()
  }

  updateEvent = () => {
    const { staffEvent } = this.state
    const { updateEventForSelection } = this.props

    const eventParamaters = this.staffEventController.getEventParameters()
    const selectedShiftId = eventParamaters.get('shiftId')
    const selectedStaffEvent = staffEvent.find((item) => item.shiftId === selectedShiftId)
    const { type } = selectedStaffEvent || {}

    const { noteToAdd, notesToUpdate } = this.notesController

    return updateEventForSelection(eventParamaters, { type }, noteToAdd, notesToUpdate)
  }

  changeEvent = async () => {
    const { changeStaffEventForSelection, cell, getStaffEventDetails } = this.props
    const { selectedStaff } = this.state
    let eventParameters = this.staffEventController.getEventParameters()
    const { note } = this.notesController
    if (cell.get('isAvatarCell')) {
      eventParameters = eventParameters.set('addStaffToOnCallShift', true).set('staff', selectedStaff)
    }
    getStaffEventDetails?.(eventParameters)

    return changeStaffEventForSelection(eventParameters, note)
  }

  delete = async () => {
    const { deleteSelectedStaffEvents, onCancel, cell } = this.props
    const { selectedEventTemplateId } = this.staffEventController
    const cellStaffEvent = (cell.get('staffEvents') || [])
      ?.toJS()
      .filter((e) => selectedEventTemplateId?.includes(e.shiftId))
    const staffEventId = cellStaffEvent[0]?.id

    deleteSelectedStaffEvents(staffEventId)
    onCancel()
  }

  getStaff = (staffId) => {
    return this.getStaffList().find((staff) => staff?.get('id') === staffId)
  }

  getUnitStaffList = (unitId) => {
    const staffList = this.getStaffList().sort((a, b) => a.get('fullName')?.localeCompare(b.get('fullName')))
    return staffList.filter((staff) => {
      return staff?.getIn(['staffProfile', 'eligibleUnits'])?.some((unit) => unit.get('homeUnitId') === unitId)
    })
  }
  getStaffList = () => {
    const { getViewModel } = this.props
    let staffList = List([])
    const shifts = getViewModel({}, false, true).filter((row) => row.get('kind') === 'shift')
    shifts.forEach((e) => {
      const shiftStaffList = e.get('staff')
      if (shiftStaffList?.size) {
        shiftStaffList?.forEach((staff) => {
          if (staff.get('fullName')) {
            staffList = [...staffList, staff]
          }
        })
      }
    })
    return staffList
  }

  getResourceTypesFromEventTemplates() {
    const { shiftsMap, cell } = this.props
    const { selectedParameters, eventTemplates } = this.staffEventController
    const eventTemplatesToSearch = hasShiftEvent(cell) ? List(selectedParameters.eventTemplate) : eventTemplates
    return (
      eventTemplatesToSearch
        ?.map((e) => {
          const resourceType = shiftsMap.get(e.shiftId)?.get('resourceType')
          const resourceTypeLabel = resourceTypeLabels[resourceType]
          if (e.shiftId && !resourceType) {
            return Map({ id: 'shift', name: resourceTypeLabels['shift'] })
          }
          return Map({ id: resourceType, name: resourceTypeLabel })
        })
        .filter((rt) => !!rt.get('id'))
        .toSet()
        .toList()
        .sort((a, b) => {
          const order = ['shift', 'equipment', 'subspeciality']
          return order.indexOf(a.get('id')) - order.indexOf(b.get('id'))
        }) || List()
    )
  }
}
