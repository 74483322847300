import { ComponentController } from 'Common/components'
import { ShiftService, StaffWorkingHoursService } from 'services'
import { getDateTimeDetails } from 'Manager/Calendar/layoutUtils'

export default class EventTemplateController extends ComponentController {
  changeEventTemplate(eventTemplateId) {
    const { setEventTemplate } = this.props
    setEventTemplate(eventTemplateId)
  }

  setEventTemplateStartsAt(startsAt) {
    const { setEventTemplate } = this.props
    setEventTemplate(null, { startsAt })
  }

  setEventTemplateEndsAt(endsAt) {
    const { setEventTemplate } = this.props
    setEventTemplate(null, { endsAt })
  }

  getStaffEventPeriodLimits(eventTemplate, step) {
    const { timeService } = this.props
    const { isMeetingEvent, startsAt, endsAt } = eventTemplate

    if (!startsAt || !endsAt) {
      return {}
    }

    const eventLimitsByDate = this._getEventLimitsByDate(eventTemplate, step)

    if (isMeetingEvent) {
      return eventLimitsByDate
    }

    const { maxStartsAt: maxStartsAtByDay, minEndsAt: minEndsAtByDay, minStartsAt, maxEndsAt } = eventLimitsByDate

    const { maxStartsAt: maxStartsAtByShift, minEndsAt: minEndsAtByShift } = this._getLimitsByShift(eventTemplate, step)

    const maxStartsAt = timeService.timeMoment(maxStartsAtByDay).isBefore(maxStartsAtByShift)
      ? maxStartsAtByDay
      : maxStartsAtByShift

    const minEndsAt = timeService.timeMoment(minEndsAtByDay).isAfter(minEndsAtByShift)
      ? minEndsAtByDay
      : minEndsAtByShift

    return { minStartsAt, maxStartsAt, minEndsAt, maxEndsAt }
  }

  _getLimitsByShift(eventTemplate, step) {
    const { timeService, shiftsMap } = this.props
    const { startsAt, shiftId } = eventTemplate

    const shift = shiftsMap.get(shiftId)
    const shiftService = new ShiftService(shift, timeService)
    const shiftPeriod = shiftService.getShiftPeriodForDate(startsAt)

    const { startsAt: shiftStartsAt, endsAt: shiftEndsAt } = shiftPeriod

    const shiftEndsAtMoment = timeService.timeMoment(shiftEndsAt)
    const shiftStartsAtMoment = timeService.timeMoment(shiftStartsAt)

    const maxStartsAt = shiftEndsAtMoment.add(-step, 'minute').toISOString()
    const minEndsAt = shiftStartsAtMoment.add(step, 'minute').toISOString()

    return { maxStartsAt, minEndsAt }
  }

  _getEventLimitsByDate(eventTemplate, step) {
    const { timeService } = this.props
    const { startsAt, endsAt } = eventTemplate

    const startsAtMoment = timeService.timeMoment(startsAt)
    const endsAtMoment = timeService.timeMoment(endsAt)

    const startOfDay = startsAtMoment.clone().startOf('day')
    const endOfDay = startsAtMoment.clone().endOf('day')

    const limitByEndsAt = endsAtMoment.clone().add(-24, 'hour')
    const eventMinStartsAtMoment = startOfDay.isAfter(limitByEndsAt) ? startOfDay : limitByEndsAt

    const eventMaxStartsAtMoment = endOfDay.clone().add(-step, 'minute')

    const minStartsAt = eventMinStartsAtMoment.toISOString()
    const maxStartsAt = eventMaxStartsAtMoment.toISOString()

    const eventMinEndsAtMoment = startsAtMoment.clone().add(step, 'minute')
    const eventMaxEndsAtMoment = startsAtMoment.clone().add(24, 'hour')

    const minEndsAt = eventMinEndsAtMoment.toISOString()
    const maxEndsAt = eventMaxEndsAtMoment.toISOString()

    return { minStartsAt, maxStartsAt, minEndsAt, maxEndsAt }
  }
  getStartEndTime(shiftId) {
    const { selectedStaff, shiftsMap, day, timeService } = this.props
    const shift = shiftsMap?.get(shiftId)

    const { dateTime, dateTimeMoment, currentDay } = getDateTimeDetails(day, timeService)

    const shiftService = new ShiftService(shift, timeService)
    const staffService = new StaffWorkingHoursService(selectedStaff)

    if (selectedStaff) {
      const { startTime, endTime } = staffService.getWorkingHoursPeriodForDay(currentDay)
      if (startTime && endTime) {
        return {
          startsAt: timeService.applyTimeToDate(startTime, dateTime),
          endsAt: timeService.applyTimeToDate(endTime, dateTime)
        }
      }
    }

    const { startsAt, endsAt } = shiftService.getShiftPeriodForDate(dateTimeMoment)
    return { startsAt, endsAt }
  }

  updateStartEndTimeForStaff() {
    const { setEventTemplate, selectedEventTemplateId } = this.props

    const selectedEventTemplate = this.props.eventTemplate.find((item) => item.id === selectedEventTemplateId)
    const shiftId = selectedEventTemplate.shiftId

    const { startsAt, endsAt } = this.getStartEndTime(shiftId)

    setEventTemplate(null, { startsAt, endsAt })
  }
}
