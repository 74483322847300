import { Component } from 'react'
import { List, Map } from 'immutable'
import { isEqual, uniqBy } from 'lodash'
import { ImmutableSelect } from 'Manager/components'
import EventTemplateController from './EventTemplateController'
import { CustomTimeRangeInput } from 'components/Form/CustomTimeRangeInput'
import { t } from 'i18n'

export default class StaffEventTemplateSelector extends Component {
  constructor(props) {
    super(props)
    this.eventTemplateController = new EventTemplateController(this)
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props)
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.selectedStaff !== prevProps.selectedStaff ||
      this.props.selectedEventTemplateId !== prevProps.selectedEventTemplateId
    ) {
      this.eventTemplateController.updateStartEndTimeForStaff()
    }
  }

  render() {
    const { day, timeService, shiftsMap, hasEvent, selectedEventTemplateId, eventTemplate, cell, shiftId } = this.props
    let eventTemplates = this.props.eventTemplates
    const tempSelectedEventTemplate = this.props.eventTemplate.find((item) => item.id === selectedEventTemplateId)
    let selectedEventTemplate
    if (tempSelectedEventTemplate) {
      selectedEventTemplate = tempSelectedEventTemplate
    } else {
      selectedEventTemplate = eventTemplate[0]
    }
    const { startsAt, endsAt, id: eventTemplateId } = selectedEventTemplate

    if (hasEvent) {
      let tempEventTemplate = []
      eventTemplate.forEach((et) => {
        const found = eventTemplates.find((item) => item.id === et.id)
        if (found) {
          tempEventTemplate.push(found)
        }
      })
      const uniqueEventTemplates = uniqBy(tempEventTemplate, 'id')
      eventTemplates = List(uniqueEventTemplates)
    }
    const options = eventTemplates.reduce((memo, template) => {
      const { id, isMeetingEvent, shiftId: templateShiftId } = template
      const shiftName = templateShiftId && shiftsMap.getIn([templateShiftId, 'name'])
      const name = isMeetingEvent ? 'Event' : shiftName
      const option = Map({ id, name })
      return memo.push(option)
    }, List())

    const templateSelectProps = {
      options,
      disabled: !eventTemplateId,
      value: eventTemplateId,
      onChange: this.onEventTemplateChange
    }

    const step = 15
    const date = day.get('dateTime')
    const limits = this.eventTemplateController.getStaffEventPeriodLimits(selectedEventTemplate, step)

    const timeRangeProps = {
      ...limits,
      step,
      date,
      timeService,
      startsAt,
      endsAt,
      onChangeStartsAt: this.onChangeStartsAt,
      onChangeEndsAt: this.onChangeEndsAt,
      readOnly: true
    }

    let shiftLabel = t('calendar.shift')
    const resourceType = shiftsMap.getIn([selectedEventTemplate.shiftId, 'resourceType'])
    if (resourceType) {
      shiftLabel = t(`calendar.${resourceType}`)
    }

    return (
      <>
        <div className="row order1">
          <div className="form-item col col-3 text-right pr10">
            <label>{shiftLabel}</label>
          </div>
          <div className="form-item col col-9">
            <ImmutableSelect {...templateSelectProps} disabled={cell.get('isAvatarCell')}>
              <option value={-1} disabled={true}>
                Select a position
              </option>
            </ImmutableSelect>
          </div>
        </div>
        <div className="row order4">
          <div className="form-item col col-3 text-right pr10">
            <label>WORKING HOURS</label>
          </div>
          <div className="form-item col col-9">
            <CustomTimeRangeInput {...timeRangeProps} />
          </div>
        </div>
      </>
    )
  }

  onEventTemplateChange = (e) => {
    const eventTemplateId = e.currentTarget.value
    this.eventTemplateController.changeEventTemplate(eventTemplateId)
    setTimeout(() => {
      this.props.loadNotes()
    }, 10)
  }

  onChangeStartsAt = (startsAt) => {
    this.eventTemplateController.setEventTemplateStartsAt(startsAt)
  }

  onChangeEndsAt = (endsAt) => {
    this.eventTemplateController.setEventTemplateEndsAt(endsAt)
  }
}
