import { PureComponent, createRef } from 'react'
import { Route } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import Toolbar from './Toolbar'
import AutoScheduleCalendarToolbar from '../../components/Form/AutoScheduleModal/Calendar/AutoScheduleCalendarToolbar/AutoScheduleCalendarToolbar.js'
import Header from './Header'
import CrossHatch from 'Manager/components/CrossHatch'
import { pick } from 'lodash'
import PrintModal from './PrintModal'
import StaffViewCellContextMenu from './StaffViewCellContextMenu'
import classNames from 'classnames'
import CellTooltip from './CellTooltip'
import JobProcessingState from './JobProcessingState'
import { paths } from 'Navigation'
import PrintView from './PrintView'
import ReactResizeDetector from 'react-resize-detector'
import {
  ActionController,
  CellTooltipController,
  DataController,
  EventController,
  FilterController,
  MultiselectController,
  PopupController,
  PrintController,
  StateController,
  ViewController,
  ViewModelController
} from './Controllers'
import stores from 'stores'
import Subscriptions from './Subscriptions'
import { List, Map } from 'immutable'
import VGrid from './Grid/VGrid'
import DayGrid from './Grid/DayGrid'
import WeekGrid from './Grid/WeekGrid'
import { OpenShiftPopup } from './OpenShiftPopup'
import CellDetails from './CellDetails'
import WarningModal from './SecondaryStaff/WarningModal'
import MismatchWarningModal from './MismatchWarningModal'
import {
  getCellContextMenuProps,
  getCellDetailsProps,
  composeIndicatorsClasses,
  componseModeClasses,
  getAppropriateStore
} from './layoutUtils'
import '../components/PopUp/PopUp.scss'
import './Calendar.scss'
import './Cell.scss'
import './CellDetails/CellDetails.scss'
import './StaffViewCellContextMenu/StaffViewCellContextMenu.scss'
import './Grid/VRole/Role.scss'
import './Grid/EligibleStaff.scss'
import './Layout.print.scss'
import LoggingService from '../../services/LoggingService.ts'
import { USER_EVENTS } from '../../Common/constants/UserEvents.ts'
import { DateRangeService } from 'services'
import { v4 as uuidv4 } from 'uuid'
import WorkingHoursMismatchModal from './WorkingHoursMismatchModal'

const { expertiseStore } = stores
// TODO: in progress => deprecate `config` in order to have all the filters
//       on the same immutable object.

export class Layout extends PureComponent {
  constructor(props) {
    super(props)
    const { calendarType } = this.props
    const isAutoScheduleCalendar = calendarType === 'autoScheduleCalendar'
    if (!isAutoScheduleCalendar) {
      this.actionController = new ActionController(this)
      this.printController = new PrintController(this)
    }

    this.viewController = new ViewController(this)
    this.multiselectController = new MultiselectController(this)
    this.popupController = new PopupController(this)
    this.eventController = new EventController(this)
    this.cellTooltipController = new CellTooltipController(this)
    this.dataController = new DataController(this)
    this.stateController = new StateController(this)
    this.viewModelController = new ViewModelController(this)
    this.filterController = new FilterController(this)

    const filters = this.filterController.buildFilters()

    this.state = {
      // isLoading: false,
      isMuted: false,
      isMouseLeave: false,
      ...this.stateController.defaultState,
      ...this.viewController.defaultState,
      ...this.popupController.defaultState,
      ...this.multiselectController.defaultState,
      ...this.cellTooltipController.defaultState,
      cellsPathsInProcessing: List(),
      scrollTop: 0,
      offsetHeight: 0,
      cellTooltipProps: {
        cell: Map(),
        cellPosition: {}
      },
      offsetWidth: 0,
      filters,
      jobProcessing: {
        type: null,
        state: null, // enum['done', 'error', 'processing']
        message: ''
      },
      overscanRowCount: 0,
      expertiseMismatchDetails: {},
      workingHoursMismatchDetails: {},
      eventStaffDetails: {},
      isLoading: false
    }

    if (!isAutoScheduleCalendar) {
      this.state = { ...this.state, ...this.actionController.defaultState, ...this.printController.defaultState }
    }
    this.headerRef = createRef()
    this.crossHatchRef = createRef()
    this.cellContextMenuRef = createRef()
    this.openShiftPopupRef = createRef()
    this.gridRef = createRef()
    this.userSessionId = null
    this.updateUserSessionId()
    this._boundWindowUnloadHandle = this._handleWindowUnload.bind(this)
    window.addEventListener('unload', this._boundWindowUnloadHandle)
  }

  updateUserSessionId() {
    this.userSessionId = uuidv4().toString()
  }

  _handleWindowUnload() {
    this._handleLogForComponentExit()
  }

  toggleIsCollapsed = (role) => {
    const roleId = role.get('id')
    this.setState(({ filters }) => {
      const isCollapsed = filters.getIn(['collapsed', roleId]) === true
      const newFilters = filters.setIn(['collapsed', roleId], !isCollapsed)
      return {
        filters: newFilters
      }
    })
  }

  resetOtherStaffFilter = () => {
    this.setState(({ filters }) => {
      const newFilters = filters.setIn(['collapsed', 'secondary-staff'], true)
      return {
        filters: newFilters
      }
    })
  }

  setExpertiseMismatchDetails = (context, eventShiftId, selection, note) => {
    const { errors = [] } = context
    const expertiseMismatchDetails = {
      errors,
      eventShiftId,
      selection,
      note
    }
    this.setState({ expertiseMismatchDetails })
  }

  setWorkingHoursMismtachDetails = (handleSubmit, eventShiftId, startTime, endTime) => {
    this.setState({
      workingHoursMismatchDetails: {
        handleSubmit,
        eventShiftId,
        startTime,
        endTime
      }
    })
  }

  updateScrollTop = (scrollTop) => {
    this.setState({ scrollTop })
    this.crossHatchRef.current.hideHorizontal()
  }

  updateCalendarDimensions = () => {
    this.setState({
      offsetHeight: this.gridRef.current.offsetHeight,
      offsetWidth: this.gridRef.current.offsetWidth
    })
  }

  loadExpertises() {
    return expertiseStore.loadExpertises()
  }

  componentDidMount() {
    document.addEventListener('click', this.eventController.onClickOutsideTheGrid)

    const { unit } = this.props
    const { offsetHeight, offsetWidth } = this.state
    const isUnitReady = unit?.get('isReady')

    this.loadExpertises()

    if (isUnitReady) {
      this.filterController.initializeFilters()
    }

    if (this.gridRef.current && (!offsetHeight || !offsetWidth)) {
      this.updateCalendarDimensions()
    }
  }

  componentWillUnmount() {
    this._handleLogForComponentExit()

    document.removeEventListener('click', this.eventController.onClickOutsideTheGrid)
    window.removeEventListener('unload', this._boundWindowUnloadHandle)
  }

  _handleLogForComponentExit() {
    const { appState } = this.props
    const userId = appState.getIn(['authentication', 'facilityUser', 'userId'])

    if (this.currentScheduleId) {
      this._logUserExitedSchedule(userId, this.currentScheduleId)

      if (this.currentScheduleState === 'balancing') {
        this._logUserExitedBalancingSchedule(userId, this.currentScheduleId)
      }
    }
  }

  // TODO: Optional chaining needs to be removed.
  componentDidUpdate(prevProps, prevState) {
    const { calendarType } = this.props
    if (calendarType === 'calendar') {
      this.printController.onComponentUpdated(prevProps, prevState)
    }
    this.popupController.onComponentUpdated(prevProps, prevState)

    const { unit, appState } = this.props
    const isUnitReady = unit?.get('isReady')

    if (!isUnitReady) {
      return
    }

    const userId = appState.getIn(['authentication', 'facilityUser', 'userId'])
    this.currentUnitId = this.props.unit.get('id')
    this.currentScheduleId = this.props.unit.get('schedule').get('id')
    this.currentScheduleState = this.props.unit.get('schedule').get('state')

    const didScheduleChange = this._hasChanged(this.previousScheduleId, this.currentScheduleId)
    const didUnitChange = this._hasChanged(this.previousUnitId, this.currentUnitId)
    const didScheduleStageChange =
      !didScheduleChange && this._hasChanged(this.previousScheduleState, this.currentScheduleState)

    if (didScheduleChange) {
      this.trackScheduleChanged(userId)
    }

    if (didScheduleStageChange) {
      this.trackScheduleStateChanged(userId)
    }

    if (didUnitChange) {
      this.trackUnitChanged(userId)
      this.filterController.initializeFilters()
    }
  }

  _hasChanged(previousValue, currentValue) {
    if (!previousValue && !currentValue) {
      return false
    }

    return previousValue !== currentValue
  }

  trackScheduleChanged(userId) {
    if (!this.previousScheduleId && this.currentScheduleId) {
      this._logUserEnteredSchedule(userId)

      if (this._didUserEnterBalancingSchedule()) {
        this._logUserEnteredBalancingSchedule(userId)
      }
    } else if (!this.currentScheduleId) {
      this._logUserExitedSchedule(userId, this.previousScheduleId)

      if (this._didUserExitBalancingSchedule()) {
        this._logUserExitedBalancingSchedule(userId, this.previousScheduleId)
      }

      this.updateUserSessionId()
    } else {
      this._logUserExitedSchedule(userId, this.previousScheduleId)

      if (this._didUserExitBalancingSchedule()) {
        this._logUserExitedBalancingSchedule(userId, this.previousScheduleId)
      }

      this.updateUserSessionId()

      this._logUserEnteredSchedule(userId)

      if (this._didUserEnterBalancingSchedule()) {
        this._logUserEnteredBalancingSchedule(userId)
      }

      this._logUserChangedSchedule(userId)
    }

    this.previousScheduleId = this.currentScheduleId
    this.previousScheduleState = this.currentScheduleState
  }

  trackScheduleStateChanged(userId) {
    if (this._didUserEnterBalancingSchedule()) {
      this._logUserEnteredBalancingSchedule(userId)
    } else if (this._didUserExitBalancingSchedule()) {
      this._logUserExitedBalancingSchedule(userId, this.previousScheduleId)
      this.updateUserSessionId()
    }

    this.previousScheduleState = this.currentScheduleState
  }

  _logUserChangedSchedule(userId) {
    LoggingService.logUserAction(USER_EVENTS.USER_CHANGED_SCHEDULE, {
      previousScheduleId: this.previousScheduleId,
      currentScheduleId: this.currentScheduleId,
      userId,
      userSessionId: this.userSessionId
    })
  }

  _logUserExitedSchedule(userId, scheduleId) {
    if (scheduleId) {
      LoggingService.logUserAction(USER_EVENTS.USER_EXITED_SCHEDULE, {
        scheduleId,
        userId,
        userSessionId: this.userSessionId
      })
    }
  }

  _logUserEnteredSchedule(userId) {
    LoggingService.logUserAction(USER_EVENTS.USER_ENTERED_SCHEDULE, {
      scheduleId: this.currentScheduleId,
      userId,
      userSessionId: this.userSessionId
    })
  }

  _logUserEnteredBalancingSchedule(userId) {
    LoggingService.logUserAction(USER_EVENTS.USER_ENTERED_BALANCING_SCHEDULE, {
      scheduleId: this.currentScheduleId,
      userId,
      userSessionId: this.userSessionId
    })
  }

  _logUserExitedBalancingSchedule(userId, scheduleId) {
    LoggingService.logUserAction(USER_EVENTS.USER_EXITED_BALANCING_SCHEDULE, {
      scheduleId,
      userId,
      userSessionId: this.userSessionId
    })
  }

  _logUnitChanged(userId) {
    LoggingService.logUserAction(USER_EVENTS.USER_CHANGED_UNIT, {
      previousUnitId: this.previousUnitId,
      currentUnitId: this.currentUnitId,
      userId,
      userSessionId: this.userSessionId
    })
  }

  _didUserExitBalancingSchedule() {
    return (
      // From balancing to inactive
      (this.previousScheduleState === 'balancing' && !this.currentScheduleState) ||
      // From balancing to any other schedule with state open or published
      (this.previousScheduleState === 'balancing' && this.currentScheduleState !== 'balancing') ||
      // From balancing to any other balancing schedule
      (this.previousScheduleState === 'balancing' &&
        this.currentScheduleState === 'balancing' &&
        this.previousScheduleId !== this.currentScheduleId)
    )
  }

  _didUserEnterBalancingSchedule() {
    return this.currentScheduleState === 'balancing'
  }

  trackUnitChanged(userId) {
    if (this.previousUnitId) {
      this._logUnitChanged(userId)
    }

    this.previousUnitId = this.currentUnitId
  }

  componentWillUpdate(nextProps, nextState) {
    this.stateController.onComponentWillUpdate(nextProps, nextState)

    const { preloadData } = nextProps
    const { preloadData: prevPreloadData } = this.props
    const hasPreloadData = preloadData && !prevPreloadData

    if (hasPreloadData) {
      const { offsetHeight, overscanRowCount } = this.state
      this.dataController.preloadShifts(preloadData, overscanRowCount, offsetHeight)
    }
  }

  render() {
    const { match, unit, activeDateRange, facility, gqlClient, timeService, appState, calendarType } = this.props
    const { filters, eventStaffDetails } = this.state
    const { calendar } = this.stateController.calendar
    const { mode, date: usDate, unitUrlId: urlId } = match.params
    const isFullView = mode === 'full'
    const isDayView = mode === 'day'
    const isWeekView = mode === 'week'
    const monthExtendedActiveDateRange = DateRangeService.getMonthExtendedDateRange(activeDateRange, timeService)

    const indicators = filters.get('indicators')
    const indicatorsClasses = composeIndicatorsClasses(indicators)
    const modeClasses = componseModeClasses(mode)
    const className = classNames('col-12 _calendarGridWrapper', {
      ...modeClasses,
      ...indicatorsClasses
    })

    const showSpinner = () => {
      this.setState({ isLoading: true })
      setTimeout(() => {
        this.setState({ isLoading: false })
      }, 5000)
    }

    const { state: jobProcessingState, message: jobProcessingMessage } = this.state.jobProcessing
    const isOtherStaffLoaded = calendar.get('isOtherStaffLoaded')
    const staffHoursMap = calendar.get('staffHoursMap')

    const { popupConfig, cellTooltipConfig } = this.state
    const popupProps = popupConfig.get('popupProps')
    const popupId = popupConfig.get('popupId')
    const unitManager = appState.getIn(['authentication', 'facilityUser'])
    const expertises = appState.getIn(['expertises', 'expertiseItems'])

    const isStaffViewCellContextMenuVisible = popupId === 'StaffViewContextMenu'

    const staffViewCellContextMenuProps = getCellContextMenuProps({
      ...this.props,
      ...this.state,
      ...this.actionController,
      ...this.popupController,
      ...this,
      calendar,
      popupProps,
      onCancel: this.hidePopup,
      unitManager,
      isCellContextMenuVisible: isStaffViewCellContextMenuVisible,
      setWorkingHoursMismtachDetails: this.setWorkingHoursMismtachDetails
    })

    const isSecondaryStaffWarningVisible = popupId === 'SecondaryStaffWarning'
    const secondaryStaffWarningProps = { onClose: this.hidePopup }

    const unitId = unit.get('id')
    const eventVariants = unit.get('eventVariants')
    const isCellDetailsVisible = popupId === 'CellDetails'

    const cellDetailsProps = getCellDetailsProps({
      ...this.props,
      ...this.state,
      ...this.actionController,
      ...this.popupController,
      calendar,
      popupProps,
      unitId,
      eventVariants,
      unitManager,
      units: this.props.eligibleUnits,
      getStaffEventDetails: this.getStaffEventDetails,
      onCancel: this.hidePopup,
      isCellDetailsVisible,
      shiftsMap: facility.get('shiftsMap'),
      getViewModel: this.viewModelController.getViewModel,
      setWorkingHoursMismtachDetails: this.setWorkingHoursMismtachDetails
    })

    const facilityShiftsMap = appState.getIn(['generalData', 'facility', 'shiftsMap']) || Map()
    const unitShiftsMap = appState.getIn(['generalData', 'shiftsById']) || Map()
    const cellTooltipProps = {
      timeService,
      isCellDetailsVisible,
      isCellContextMenuVisible: isStaffViewCellContextMenuVisible,
      cellTooltipConfig,
      facilityShiftsMap,
      unitShiftsMap,
      isCellWithSelectionError: this.cellTooltipController.isCellWithSelectionError,
      // TODO: needs to map it to algoCalendar: DONE
      notes: appState.getIn([calendarType, 'notes']) || Map(),
      staffManagersMap: facility.get('staffManagersMap') || Map(),
      facilityUsersMap: activeDateRange.get('facilityUsersMap'),
      otherStaffMap: this.stateController.calendar.otherStaffMap
    }
    const isRequirementsMismatchVisible = popupId === 'RequirementsMismatchPopup'
    const isWorkingHoursMismatchVisible = popupId === 'WorkingHoursMismatchPopup'

    const requirementsMismatchProps = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        calendar,
        expertises,
        unitShiftsMap,
        eventStaffDetails,
        onCancel: this.hidePopup,
        getStaffEventDetails: this.getStaffEventDetails
      },
      [
        'actionController',
        'expertiseMismatchDetails',
        'expertises',
        'multiselectController',
        'onCancel',
        'timeService',
        'unitShiftsMap',
        'eventStaffDetails',
        'getStaffEventDetails'
      ]
    )

    const workingHoursMismatchProps = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        calendar,
        unitShiftsMap,
        eventStaffDetails,
        onCancel: this.hidePopup,
        getStaffEventDetails: this.getStaffEventDetails
      },
      [
        'actionController',
        'workingHoursMismatchDetails',
        'onCancel',
        'timeService',
        'unitShiftsMap',
        'eventStaffDetails',
        'getStaffEventDetails',
        'popupController'
      ]
    )

    const printModalProps = pick(
      {
        ...this.props,
        ...this.state,
        print: this.printController?.print,
        filterRoleIds: filters.get('roleIds'),
        filterShiftTypes: filters.get('shiftTypes'),
        generateSchedulePdf: this.printController?.generateSchedulePdf,
        resetDefaultGeneratePdfJobState: this.printController?.resetDefaultState
      },
      [
        'mode',
        'unit',
        'print',
        'timeService',
        'printStatus',
        'filterRoleIds',
        'activeDateRange',
        'filterShiftTypes',
        'generateSchedulePdf',
        'isGeneratingPdfError',
        'resetDefaultGeneratePdfJobState'
      ]
    )

    const openShiftPopupProps = pick(
      {
        ...this.props,
        ...this.state,
        calendar,
        reloadCalendarForShift: this.actionController?.reloadCalendarForShift,
        hideOpenShiftPopup: this.popupController.hideOpenShiftPopup,
        facilityUsersMap: activeDateRange.get('facilityUsersMap'),
        unitId,
        activeDateRange,
        mode,
        timeService
      },
      [
        'openShiftPopupProps',
        'calendar',
        'calendarStore',
        'timeService',
        'reloadCalendarForShift',
        'openShiftStore',
        'hideOpenShiftPopup',
        'facilityUsersMap',
        'unitId',
        'filters',
        'activeDateRange',
        'mode',
        'timeService'
      ]
    )
    const jobProcessingProps = pick(
      {
        message: jobProcessingMessage,
        currentState: jobProcessingState
      },
      ['message', 'currentState']
    )

    const subscriptionsProps = { gqlClient }
    const schedule = unit.get('schedule')
    const vGridProps = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        ...this.eventController,
        ...this.popupController,
        ...this.dataController,
        schedule,
        calendar,
        calendarType: calendarType,
        mode,
        date: usDate,
        getViewModel: this.viewModelController.getViewModel,
        getRole: this.viewController.getRole,
        getShift: this.viewController.getShift,
        unitUrlId: urlId,
        showOpenShiftPopup: this.popupController.showOpenShiftPopup,
        hideOpenShiftPopup: this.popupController.hideOpenShiftPopup,
        updateCellTooltip: this.updateCellTooltip,
        displayNameAs: this.state.filters.getIn(['displayOptions', 'staff', 'displayNameAs']),
        staffHoursMap,
        onChangeViewPreference: this.dataController.updateUnitManagerViewPreference,
        unitId,
        otherStaffMap: this.stateController.calendar.otherStaffMap,
        facilityShiftsMap: appState.getIn(['generalData', 'facility', 'shiftsMap']) || Map(),
        notes: appState.getIn(['calendar', 'notes']) || Map(),
        monthExtendedActiveDateRange
      },
      [
        'mode',
        'date',
        'filters',
        'timeService',
        'showPopup',
        'updateScrollTop',
        'toggleIsCollapsed',
        'resetOtherStaffFilter',
        'selection',
        'loadOtherStaff',
        'activeDateRange',
        'onCellEvent',
        'offsetHeight',
        'unitUrlId',
        'calendar',
        'getRole',
        'getShift',
        'schedule',
        'loadShift',
        'resetLoadConfig',
        'openShiftPopupProps',
        'loadCalendar',
        'getViewModel',
        'onMouseMoveOverGrid',
        'onMouseLeave',
        'cellsPathsInProcessing',
        'showOpenShiftPopup',
        'hideOpenShiftPopup',
        'updateCellTooltip',
        'overscanRowCount',
        'displayNameAs',
        'staffHoursMap',
        'otherStaffMap',
        'onChangeViewPreference',
        'unitId',
        'facilityShiftsMap',
        'notes',
        'calendarType',
        'monthExtendedActiveDateRange'
      ]
    )

    const dayGridProps = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        ...this.actionController,
        ...this.eventController,
        ...this.popupController,
        ...this.dataController,
        schedule,
        calendar,
        mode,
        usDate,
        unit,
        unitId,
        getViewModel: this.viewModelController.getViewModel,
        getDayViewModel: this.viewModelController.getDayViewModel,
        getRole: this.viewController.getRole,
        getShift: this.viewController.getShift,
        unitUrlId: urlId,
        showOpenShiftPopup: this.popupController.showOpenShiftPopup,
        hideOpenShiftPopup: this.popupController.hideOpenShiftPopup,
        updateCellTooltip: this.updateCellTooltip,
        displayNameAs: this.state.filters.getIn(['displayOptions', 'staff', 'displayNameAs']),
        facilityShiftsMap,
        onChangeViewPreference: this.dataController.updateUnitManagerViewPreference,
        unitShiftsMap,
        staffManagersMap: facility.get('staffManagersMap') || Map(),
        getUnitManagerViewPreferences: this.dataController.getUnitManagerViewPreferences
      },
      [
        'filters',
        'timeService',
        'showPopup',
        'updateScrollTop',
        'toggleIsCollapsed',
        'resetOtherStaffFilter',
        'selection',
        'loadOtherStaffWithEvents',
        'updateEvent',
        'activeDateRange',
        'onCellEvent',
        'offsetHeight',
        'unitUrlId',
        'calendar',
        'getRole',
        'getShift',
        'schedule',
        'loadShift',
        'resetLoadConfig',
        'openShiftPopupProps',
        'loadCalendarDayView',
        'loadCalendar',
        'getViewModel',
        'getDayViewModel',
        'onMouseMoveOverGrid',
        'onMouseLeave',
        'cellsPathsInProcessing',
        'showOpenShiftPopup',
        'hideOpenShiftPopup',
        'updateCellTooltip',
        'overscanRowCount',
        'displayNameAs',
        'mode',
        'usDate',
        'isOtherStaffLoaded',
        'unit',
        'unitId',
        'facilityShiftsMap',
        'onChangeViewPreference',
        'unitShiftsMap',
        'staffManagersMap',
        'getUnitManagerViewPreferences'
      ]
    )

    //To be implemented while implemeting the week view data
    const weekGridProps = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        ...this.eventController,
        ...this.popupController,
        ...this.dataController,
        schedule,
        calendar,
        calendarType: calendarType,
        mode,
        date: usDate,
        getViewModel: this.viewModelController.getViewModel,
        getRole: this.viewController.getRole,
        getShift: this.viewController.getShift,
        getCurrentWeekCells: this.viewModelController.getCurrentWeekCells.bind({ props: this.props }),
        unitUrlId: urlId,
        showOpenShiftPopup: this.popupController.showOpenShiftPopup,
        hideOpenShiftPopup: this.popupController.hideOpenShiftPopup,
        updateCellTooltip: this.updateCellTooltip,
        displayNameAs: this.state.filters.getIn(['displayOptions', 'staff', 'displayNameAs']),
        staffHoursMap,
        onChangeViewPreference: this.dataController.updateUnitManagerViewPreference,
        unitId,
        otherStaffMap: this.stateController.calendar.otherStaffMap,
        facilityShiftsMap: appState.getIn(['generalData', 'facility', 'shiftsMap']) || Map(),
        notes: appState.getIn(['calendar', 'notes']) || Map(),
        monthExtendedActiveDateRange
      },
      [
        'mode',
        'date',
        'filters',
        'timeService',
        'showPopup',
        'updateScrollTop',
        'toggleIsCollapsed',
        'resetOtherStaffFilter',
        'selection',
        'loadOtherStaff',
        'activeDateRange',
        'onCellEvent',
        'offsetHeight',
        'unitUrlId',
        'calendar',
        'getRole',
        'getShift',
        'schedule',
        'loadShift',
        'resetLoadConfig',
        'openShiftPopupProps',
        'loadCalendar',
        'getViewModel',
        'onMouseMoveOverGrid',
        'onMouseLeave',
        'cellsPathsInProcessing',
        'showOpenShiftPopup',
        'hideOpenShiftPopup',
        'updateCellTooltip',
        'overscanRowCount',
        'displayNameAs',
        'staffHoursMap',
        'otherStaffMap',
        'onChangeViewPreference',
        'unitId',
        'facilityShiftsMap',
        'notes',
        'calendarType',
        'monthExtendedActiveDateRange',
        'getCurrentWeekCells'
      ]
    )

    const printViewProps = pick(
      {
        ...this.props,
        ...this.state,
        ...this.dataController,
        ...this.viewModelController,
        mode,
        calendar,
        isOtherStaffLoaded,
        print: this.printController?.print,
        printedDateRange: activeDateRange,
        printingFilters: this.printController?.getPrintingFilters(),
        facilityShiftsMap: appState.getIn(['generalData', 'facility', 'shiftsMap']) || Map(),
        notes: appState.getIn(['calendar', 'notes']) || Map(),
        monthExtendedActiveDateRange
      },
      [
        'unit',
        'mode',
        'filters',
        'calendar',
        'loadShift',
        'printStatus',
        'timeService',
        'authentication',
        'loadOtherStaff',
        'printingFilters',
        'loadCalendarDay',
        'getDayViewModel',
        'resetLoadConfig',
        'activeDateRange',
        'printedDateRange',
        'getViewModelTree',
        'isCalendarLoaded',
        'isOtherStaffLoaded',
        'facilityShiftsMap',
        'notes',
        'monthExtendedActiveDateRange'
      ]
    )

    const toolbarProps = pick(
      {
        ...this.props,
        ...this.state,
        usDate,
        urlId,
        isShiftDaysLoaded: true,
        updateMutedState: this.updateMutedState,
        updateJobProcessingState: this.updateJobProcessingState,
        mode,
        filters: this.state.filters,
        updateFilter: this.filterController.updateFilter,
        updateFilterRole: this.filterController.updateFilterRole,
        onChangeDisplayOptions: this.filterController.onChangeDisplayOptions,
        onChangeViewResourceType: this.filterController.onChangeViewResourceType,
        handleStaffStrategy: this.handleStaffStrategy,
        sendSchedulePdf: this.printController?.sendSchedulePdf,
        onChangeViewPreference: this.dataController.updateUnitManagerViewPreference,
        getUnitManagerViewPreferences: this.dataController.getUnitManagerViewPreferences
      },
      [
        'calendar',
        'Dialog',
        'calendarStore',
        'isEnabledFeature',
        'isFacilityEnabledFeature',
        'unit',
        'urlId',
        'authentication',
        'generalStore',
        'eligibleUnits',
        'usDate',
        'facilityName',
        'facilitiesCount',
        'activeDateRange',
        'updateMutedState',
        'isShiftDaysLoaded',
        'updateJobProcessingState',
        'timeService',
        'mode',
        'filters',
        'updateFilter',
        'updateFilterRole',
        'onChangeDisplayOptions',
        'onChangeViewResourceType',
        'sendSchedulePdf',
        'constraintStore',
        'algoScheduleStore',
        'calendarType',
        'onChangeViewPreference',
        'getUnitManagerViewPreferences'
      ]
    )

    const autoScheduleToolbarProps = pick(
      {
        ...this.props,
        ...this.state,
        usDate,
        urlId,
        isShiftDaysLoaded: true,
        updateMutedState: this.updateMutedState,
        updateJobProcessingState: this.updateJobProcessingState,
        mode,
        filters: this.state.filters,
        updateFilter: this.filterController.updateFilter,
        updateFilterRole: this.filterController.updateFilterRole,
        onChangeDisplayOptions: this.filterController.onChangeDisplayOptions,
        handleStaffStrategy: this.handleStaffStrategy,
        activeDateRange: monthExtendedActiveDateRange
      },
      [
        'calendar',
        'Dialog',
        'autoScheduleCalendarStore',
        'isEnabledFeature',
        'isFacilityEnabledFeature',
        'unit',
        'urlId',
        'authentication',
        'generalStore',
        'eligibleUnits',
        'usDate',
        'facilityName',
        'facilitiesCount',
        'activeDateRange',
        'updateMutedState',
        'isShiftDaysLoaded',
        'updateJobProcessingState',
        'timeService',
        'mode',
        'filters',
        'updateFilter',
        'updateFilterRole',
        'onChangeDisplayOptions',
        'sendSchedulePdf'
      ]
    )
    toolbarProps.KpiResult = appState.getIn(['autoScheduleCalendar', 'kpi'])

    return (
      <>
        <ReactResizeDetector handleWidth handleHeight onResize={this.updateCalendarDimensions} />
        <aside className="hx-main-navigation" ref={this.headerRef}>
          {/* <TopNavigation {...topNavigationProps} /> */}
          {/*<Navigation {...navigationProps} />*/}
          {this._isMainCalendar() ? (
            <Toolbar {...toolbarProps} />
          ) : (
            <AutoScheduleCalendarToolbar {...autoScheduleToolbarProps} />
          )}
          {/* How calendar is being used */}
          {this.state.isLoading && <sh-spinner overlay label="Calendar is being updated"></sh-spinner>}
          <div onMouseMove={this.updateCrossHatchPosition} onMouseLeave={this.hideCrosshatch}>
            <Header
              showSpinner={showSpinner}
              filters={this.state.filters}
              updateFilter={this.filterController.updateFilter}
              mode={mode}
              activeDateRange={monthExtendedActiveDateRange}
              unit={unit}
              timeService={timeService}
              calendarType={this.props.calendarType}
            />
          </div>
        </aside>
        <section
          ref={this.gridRef}
          className={className}
          id="hx-calendar"
          onMouseMove={this.onMouseMoveOverGrid}
          tabIndex={-1}
          onClick={this.clickOnGrid}
          onMouseLeave={this.onMouseLeave}
        >
          {this._isMainCalendar() && <OpenShiftPopup ref={this.openShiftPopupRef} {...openShiftPopupProps} />}
          <CellTooltip {...cellTooltipProps} />
          <CrossHatch ref={this.crossHatchRef} />
          {this._isMainCalendar() && <JobProcessingState {...jobProcessingProps} />}
          {isFullView && <VGrid {...vGridProps} />}
          {this._isMainCalendar() && isDayView && <DayGrid {...dayGridProps} />}
          {isWeekView && <WeekGrid {...weekGridProps} />}

          {this._isMainCalendar() && (
            <>
              <PrintView {...printViewProps} />
              <StaffViewCellContextMenu ref={this.cellContextMenuRef} {...staffViewCellContextMenuProps} />
            </>
          )}
          {this._isMainCalendar() && isSecondaryStaffWarningVisible && <WarningModal {...secondaryStaffWarningProps} />}
          {this._isMainCalendar() && isRequirementsMismatchVisible && (
            <MismatchWarningModal {...requirementsMismatchProps} />
          )}

          {this._isMainCalendar() && isWorkingHoursMismatchVisible && (
            <WorkingHoursMismatchModal {...workingHoursMismatchProps} />
          )}

          {this._isMainCalendar() && <CellDetails {...cellDetailsProps} />}
        </section>
        {this._isMainCalendar() && (
          <>
            <Route path={paths.PrintPDF} render={(props) => <PrintModal {...props} {...printModalProps} />} />
            <Subscriptions {...subscriptionsProps} />
          </>
        )}
        {navigator.platform.startsWith('Win') && <style>.hx-scrollbar-hack::-webkit-scrollbar {'{width: auto}'}</style>}
      </>
    )
  }

  _isMainCalendar() {
    return this.props.calendarType === 'calendar'
  }

  updateMutedState = (isMuted) => {
    this.setState({ isMuted })
  }

  updateJobProcessingState = (jobProcessing) => {
    this.setState({ jobProcessing })
  }

  onMouseLeave = () => {
    this.hideCrosshatch()
  }

  onMouseMoveOverGrid = (event) => {
    this.updateCrossHatchPosition(event)
  }

  clickOnGrid = (e) => {
    // this.openShiftPopupRef.current.handleClick(e);
  }

  hideCrosshatch = () => {
    this.crossHatchRef.current.hide()
  }

  updateCrossHatchPosition = (e) => {
    this.crossHatchRef.current.updatePosition(e)
  }

  showCellDetailsPopup = () => this.popupController.showCellDetailsPopup()

  hidePopup = () => this.popupController.hidePopup()

  getStaffEventDetails = (eventStaffDetails) => this.setState({ eventStaffDetails })

  resetSelection = () => this.multiselectController.cancelMultiselect()

  hideContextMenu = () => this.popupController.hidePopup()

  updateCellTooltip = ({ event, cell, staffPath, isMouseLeave = false, meta }) => {
    const current = this.headerRef
    const { calendar, timeService } = this.props
    const cellIndex = event.currentTarget.dataset.cellIndex

    this.cellTooltipController.showCellTooltip({
      event,
      cell,
      current,
      calendar,
      staffPath,
      timeService,
      isMouseLeave,
      cellIndex,
      meta
    })
  }
}

export default withAppContext((props) => {
  const { appState, activeDateRange, calendarType } = props
  const calendar = appState.get(calendarType)
  let store = getAppropriateStore(calendarType)
  const isSameDateRange = activeDateRange === calendar.get('dateRange')
  return (
    <Layout
      {...props}
      eligibleUnits={appState.getIn(['generalData', 'eligibleUnits'])}
      facility={appState.getIn(['generalData', 'facility'])}
      facilityName={appState.getIn(['authentication', 'facility', 'name'])}
      generalData={appState.get('generalData')}
      calendar={isSameDateRange ? calendar : store.defaultState}
      preloadData={appState.getIn(['generalData', 'preloadData'])}
      facilitiesCount={appState.getIn(['authentication', 'facilities']).size}
      authentication={appState.get('authentication')}
    />
  )
})
