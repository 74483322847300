import { Component, createRef } from 'react'
import { formatPhoneNumber } from 'utils'
import { startCase } from 'lodash'
import { Avatar, CrudCard } from 'Manager/components'
import { navigate } from 'Navigation'
import { Staff } from 'entityWrappers'
import { t } from 'i18n'
import Status from './Status'

const dayOfWeekKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export default class StaffProfileInfo extends Component {
  constructor(props) {
    super(props)
    this.state = { showHistory: false, moreDetailsButtonEnabled: false, staffDetailsHeight: 0 }
    this.staffDetailsRef = createRef()
    this.moreDetailsRef = createRef()
  }

  handleMoreDetails = () => {
    this.setState((prevState) => ({
      moreDetailsButtonEnabled: !prevState.moreDetailsButtonEnabled
    }))
  }

  renderPhoneNumber = (phoneNumber, type) => {
    if (phoneNumber) {
      return (
        <div className="hx-staff-profile-pn">
          <span className="hx-profile-info">
            <sh-text size="body-1">{formatPhoneNumber(phoneNumber)}</sh-text>
          </span>
          <span className="hx-staff-profile-heading">{` ${type}`}</span>
        </div>
      )
    }
    return <></>
  }

  getHomeUnitRoleName = (homeUnitRole) => {
    return homeUnitRole ? homeUnitRole.get('name') : ''
  }

  getAvailabilityType = (staffProfile) => {
    const availabilityType = staffProfile.get('availabilityType')
    if (availabilityType === 'dayTime') {
      return 'Day'
    } else if (availabilityType === 'nightTime') {
      return 'Night'
    } else {
      return 'Any'
    }
  }

  calculateStaffDetailsHeight() {
    const moreDetailsHeight = this.moreDetailsRef?.current?.offsetHeight || 0
    const detailItemPaddingTop = 10
    let staffDetailsHeight = this.staffDetailsRef?.current?.scrollHeight || 0
    if (!this.state.moreDetailsButtonEnabled) {
      staffDetailsHeight -= moreDetailsHeight - detailItemPaddingTop
    }
    return staffDetailsHeight
  }

  componentDidMount() {
    this.setState({ staffDetailsHeight: this.calculateStaffDetailsHeight() })
  }

  componentDidUpdate(_prevProps, prevState) {
    const newStaffDetailsHeight = this.calculateStaffDetailsHeight()
    if (newStaffDetailsHeight !== prevState.staffDetailsHeight) {
      this.setState({ staffDetailsHeight: newStaffDetailsHeight })
    }
  }

  render() {
    const { timeService, staff, unit, unitManager, isStaffOnly = false } = this.props
    const facilityUser = new Staff(staff)

    const {
      userId,
      facilityProfile,
      employmentType,
      email,
      firstName,
      lastName,
      managerFullName,
      accountUserId,
      phoneNumber,
      phoneNumber1,
      phoneNumber2,
      avatarUrl
    } = facilityUser
    let { maximumNumberOfHoursPerWeek } = facilityUser

    maximumNumberOfHoursPerWeek = maximumNumberOfHoursPerWeek ? maximumNumberOfHoursPerWeek : 0

    const facilityProfileWithAvatarUrl = facilityProfile.set('avatarUrl', avatarUrl)

    const { startsAt: facilityStartsAt, staffProfile } = facilityUser.staffProfile
    const position = staffProfile?.get?.('position')
    const currentEligibleUnit = facilityUser.getCurrentEligibleUnit(timeService)
    const roles = unit.get('roles')

    const homeUnitRoleId = currentEligibleUnit?.get?.('homeUnitRoleId')
    const homeUnitName = currentEligibleUnit ? currentEligibleUnit.get('homeUnitName') : ''
    const homeUnitRole = homeUnitRoleId && roles?.find((role) => role.get('id') === homeUnitRoleId)
    const homeUnitRoleName = this.getHomeUnitRoleName(homeUnitRole)
    const availabilityType = this.getAvailabilityType(staffProfile)

    const facilityStartsAtFormatted = timeService.timeMoment(facilityStartsAt).format('MMM DD, YYYY')

    let editStaffUrl
    if (isStaffOnly) {
      editStaffUrl = navigate.from.StaffProfilePage.to.StaffProfileEdit({}, false)
    } else {
      editStaffUrl = navigate.from.StaffDetails.to.StaffEdit({}, false)
    }

    const managerOtherUnitIds = unitManager.getIn(['unitManagerProfile', 'otherUnitIds'])
    const managerHomeUnitId = unitManager.getIn(['unitManagerProfile', 'homeUnitId'])
    const availableUnitIds = managerOtherUnitIds.push(managerHomeUnitId)

    const currentHomeUnitId = currentEligibleUnit?.get?.('homeUnitId')
    const isStaffInManagerUnit = availableUnitIds.includes(currentHomeUnitId)
    const arrowClassName = this.state.moreDetailsButtonEnabled ? 'up' : 'down'

    const workingHours = staffProfile.get('workingHours')

    return (
      <CrudCard
        createItemPath={editStaffUrl}
        createItemIcon="edit"
        createItemTooltip="Edit staff details"
        createItemButtonClassNames="edit"
        isCreateItemDisabled={!isStaffInManagerUnit && !isStaffOnly}
      >
        <div className="hx-staff-profile-info row pb10 pt20">
          <div className="hx-staff-profile-avatar row w100">
            <Avatar
              initialsClass="hx-staff-initials rounded hx-staff-profile-size"
              avatarClass="hx-staff-avatar rounded hx-staff-profile-size"
              profile={facilityProfileWithAvatarUrl}
              userId={userId}
              statusIcon={<Status staff={staff} />}
            />
          </div>
          <div className="row align-names pt10 col-12">
            <div data-testid="hx-profile-first-name" className="hx-profile-names w100">
              {firstName}
            </div>
            <div data-testid="hx-profile-last-name" className="hx-profile-names w100">
              {lastName}
            </div>
            <div data-testid="hx-profile-info-position-or-role-name" className="hx-profile-info w100 pt10">
              <sh-text size="body-1">{position || homeUnitRoleName}</sh-text>
            </div>
          </div>
          <div className="row pt20 hx-profile-info col-12">
            <div className="col-4 hx-center-align">
              <div>
                <i className="icon icon-briefcase hx-staff-profile-icons" />
              </div>
              <div data-testid="staff-profile-employment-type" className="pt10">
                <sh-text size="body-1">{startCase(employmentType)}</sh-text>
              </div>
            </div>
            <div className="col-4 hx-center-align">
              <div>
                <i className="icon icon-clock-regular hx-staff-profile-icons" />
              </div>
              <div className="pt10">
                <sh-text size="body-1">{`${maximumNumberOfHoursPerWeek} h/week`}</sh-text>
              </div>
            </div>
            <div className="col-4 hx-center-align">
              <div>
                <i className="icon icon-cog-solid hx-staff-profile-icons" />
              </div>
              <div className="pt10">
                <sh-text size="body-1">{availabilityType}</sh-text>
              </div>
            </div>
          </div>
          <div className="row col-12 hx-seprator"></div>
          <div
            className="row pl10 mr10 hx-staff-profile-details"
            ref={this.staffDetailsRef}
            style={{ height: `${this.state.staffDetailsHeight}px` }}
          >
            {!!homeUnitName && (
              <div className="row pt20">
                <div>
                  <div className="hx-staff-profile-heading pb10">Department</div>
                  <span className="hx-profile-info">
                    <sh-text size="body-1">{homeUnitName}</sh-text>
                  </span>
                </div>
              </div>
            )}
            {!!email && (
              <div className="row pt20 col-12">
                <div>
                  <div className="hx-staff-profile-heading pb10">Email</div>
                  <span className="hx-profile-info">
                    <sh-text size="body-1">
                      <a href={`mailto:${email}`}>{email}</a>
                    </sh-text>
                  </span>
                </div>
              </div>
            )}

            {(!!phoneNumber || !!phoneNumber1 || !!phoneNumber2) && (
              <div className="row pt20 col-12">
                <div>
                  <div className="hx-staff-profile-heading pb10">Phone</div>
                  {this.renderPhoneNumber(phoneNumber, 'Invitation')}
                  {this.renderPhoneNumber(phoneNumber1, 'Cell')}
                  {this.renderPhoneNumber(phoneNumber2, 'Work')}
                </div>
              </div>
            )}

            <div ref={this.moreDetailsRef} className="row">
              {!!accountUserId && (
                <div className="row pt20 col-12">
                  <div>
                    <div className="hx-staff-profile-heading pb10">Username</div>
                    <span className="hx-profile-info">
                      <sh-text size="body-1">{accountUserId}</sh-text>
                    </span>
                  </div>
                </div>
              )}

              {!!managerFullName && (
                <div className="row pt20 col-12">
                  <div>
                    <div className="hx-staff-profile-heading pb10">Manager</div>
                    <span className="hx-profile-info">
                      <sh-text size="body-1">{managerFullName}</sh-text>
                    </span>
                  </div>
                </div>
              )}

              {!!facilityStartsAtFormatted && (
                <div className="row pt20 col-12">
                  <div>
                    <div className="hx-staff-profile-heading pb10">Employment Dates</div>
                    <span className="hx-profile-info">
                      <sh-text size="body-1">{`${facilityStartsAtFormatted} - Present`}</sh-text>
                    </span>
                  </div>
                </div>
              )}
              {!!workingHours && (
                <div className="row pt20 col-12">
                  <div className="col-12">
                    <div className="hx-staff-profile-heading pb10">{t(`staff.staffWorkingHours.workingDetails`)}</div>
                    <div className="hx-profile-info row">
                      <div className="col-6">
                        <sh-text size="body-1">{t('staff.staffWorkingHours.workingDays')}</sh-text>
                      </div>
                      <div className="col-6">
                        <sh-text size="body-1" class="text-center">
                          {t('staff.staffWorkingHours.hoursOfOperation')}
                        </sh-text>
                      </div>
                    </div>
                    <sh-divider />
                    {dayOfWeekKeys
                      .filter((day) => {
                        const dayDetails = workingHours.get('weekDetails')?.get(day)
                        return dayDetails.get('isWorking')
                      })
                      .map((day) => {
                        const dayDetails = workingHours.get('weekDetails')?.get(day)
                        const dayOperationalHours = dayDetails.get('operationalHours')
                        const startTime = dayOperationalHours?.get('startTime')
                        const endTime = dayOperationalHours?.get('endTime')
                        const timeRange = `${startTime || ''} - ${endTime || ''}`

                        return (
                          <div className="hx-profile-info row" key={day}>
                            <div className="col-6">
                              <sh-text size="body-1">{t(`staff.staffWorkingHours.daysOfTheWeek.${day}`)}</sh-text>
                            </div>
                            <div className="col-6">
                              <sh-text size="body-1" class="text-center">
                                {timeRange}
                              </sh-text>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row col-12 hx-seprator"></div>
          <div onClick={this.handleMoreDetails} className="row col-12 pt10 hx-staff-profile-button">
            <span>{`${this.state.moreDetailsButtonEnabled ? 'Hide' : 'More'} details`}</span>
            <span>
              <i className={`icon icon-${arrowClassName}`} />
            </span>
          </div>
        </div>
      </CrudCard>
    )
  }
}
