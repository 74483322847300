export default CalendarDay()

function CalendarDay() {
  let updateCalendar = null

  const actions = {
    resetCalendarDay
  }
  return { initialize, actions }

  function initialize(context) {
    ;({ updateCalendar } = context)
  }

  function resetCalendarDay() {
    return updateCalendar((calendar) => calendar.removeIn(['daysData']))
  }
}
