import OpenShiftActivity from "./WeekOpenShifts/OpenShift/OpenShiftActivity"
import OpenShiftStatus from "./WeekOpenShifts/OpenShift/OpenShiftStatus"
import { curry } from 'i18n'

const t = curry('openShifts.cards.')

interface IOpenShiftStatusProps {
    isPosted: boolean,
    pendingToConfirmUserIds: string[],
    isFilled: boolean,
    openShift: any,
    staffMismatch: number
}

interface IOpenShiftActivityProps {
    openShift: any,
}

interface IOpenShiftDetailsRowProps {
    shiftName: string
    time: string
    roleName: string
    staffMismatch: number
    createdAtDateTime: string
    openShiftStatusProps: IOpenShiftStatusProps,
    openShiftActivityProps: IOpenShiftActivityProps
}

const OpenShiftDetailsRow = (props: IOpenShiftDetailsRowProps) => {
    const { shiftName, time, roleName, staffMismatch, createdAtDateTime, openShiftStatusProps, openShiftActivityProps } = props
    return (
        <>
            <div className="trout semibold col-3">{shiftName}</div>
            <div className="col-2">{time}</div>
            <div className="col-3">{roleName}</div>
            <div className="semibold col-1">{Math.max(staffMismatch, 0)}</div>
            <div className="semibold col-2">{t('requestType.internal')}</div>
            <OpenShiftStatus {...openShiftStatusProps} />
            <OpenShiftActivity {...openShiftActivityProps} />
            <div className="semibold col-1">{createdAtDateTime}</div>
        </>
    )
}

export default OpenShiftDetailsRow