import { Component } from 'react'
import classNames from 'classnames'
import './WorkingHours.scss'
import { merge } from 'lodash'
import OperationalHours from './OperationalHours'
// @ts-ignore
import StringInputField from '@humanics/he-react-common/lib/components/Form/StringInputField'
import { curry } from 'i18n'

const t = curry('staff.')

const DAYS_OF_WEEK = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const DAY_LABELS = {
  mon: 'MONDAY',
  tue: 'TUESDAY',
  wed: 'WEDNESDAY',
  thu: 'THURSDAY',
  fri: 'FRIDAY',
  sat: 'SATURDAY',
  sun: 'SUNDAY'
}
const defaultDetails = {
  isWorking: true
}

interface IWeekDetailsProps {
  formItemClasses?: string
  onChange: (value: any, field: string) => void
  field?: string | object
  value?: any
  defaultValues?: any
  error?: string
  definition?: any
  formData?: any
  setFormData?: (data: any) => void
  model: string
}

export default class WeekDetails extends Component<IWeekDetailsProps> {
  static defaultProps = {
    value: DAYS_OF_WEEK.reduce((acc: any, day) => {
      acc[day] = { ...defaultDetails }
      return acc
    }, {})
  }

  constructor(props: IWeekDetailsProps) {
    super(props)
    this.initializeFormData(props)
  }

  initializeFormData(props: IWeekDetailsProps) {
    const { formData, setFormData } = props
    if (!formData.isEditable) {
      setFormData?.({
        isEditable: DAYS_OF_WEEK.reduce((acc: any, day) => {
          acc[day] = false
          return acc
        }, {})
      })
    }
  }

  componentDidMount() {
    const { field, value, onChange, defaultValues } = this.props
    const inputData = DAYS_OF_WEEK.reduce((acc: any, day) => {
      acc[day] = merge({}, defaultDetails, value[day], defaultValues?.[day])
      return acc
    }, {})
    onChange(inputData, field as string)
  }

  handleChange = (day: string, field: string, e: any) => {
    const { value, onChange, formData, setFormData } = this.props
    const updatedValue = { ...value }
    updatedValue[day][field] = e && !e.hasOwnProperty('target') ? e : e ? e.target.checked : null

    if (field === 'isWorking') {
      this.updateIsWorking(day, updatedValue, formData, setFormData)
    }

    onChange(updatedValue, 'weekDetails')
  }

  updateIsWorking(day: string, updatedValue: any, formData: any, setFormData: any) {
    if (!updatedValue[day]['isWorking']) {
      updatedValue[day]['operationalHours'] = null
      setFormData?.({
        isEditable: {
          ...formData.isEditable,
          [day]: false
        }
      })
    } else {
      setFormData?.({
        isEditable: {
          ...formData.isEditable,
          [day]: formData.isEditable?.[day]
        }
      })
    }
  }

  handleCheck = (day: string, field: string) => {
    const { value, onChange, setFormData, formData } = this.props
    const updatedValue = { ...value }

    if (field === 'isEditable') {
      setFormData?.({
        isEditable: {
          ...formData?.isEditable,
          [day]: !formData?.isEditable?.[day]
        }
      })
    }
    onChange(updatedValue, 'weekDetails')
  }

  renderOperationalHours = (day: keyof typeof DAY_LABELS, key: string, field: string, definition: any) => {
    const { value: values, error, formData, defaultValues } = this.props
    const itemValue = values[day][field]
    const { format } = definition

    const handleChange = (e: any) => this.handleChange(day, field, e)

    const isEditable = formData.isEditable?.[day]
    const isWorking = values[day].isWorking
    const inputProps = {
      key: `${key}-${day}`,
      field,
      definition,
      value: itemValue,
      defaultValues: defaultValues?.[day]?.[field],
      format,
      onChange: handleChange,
      error: !itemValue ? error : undefined,
      formData,
      isEditable,
      isWorking
    }
    const dayLabel = DAY_LABELS[day]
    if (definition.inputType !== 'InputOperationalHours') {
      return (
        <>
          <div className="mb10 mr10">
            <StringInputField {...inputProps} />
          </div>
          <div className="mb30 w15">
            <label>
              <span className="form-label">{dayLabel}</span>
            </label>
          </div>
          <div className="mb30 mr10">
            <label className="switch">
              <input
                type="checkbox"
                checked={isEditable}
                onChange={(e) => this.handleCheck(day, 'isEditable')}
                disabled={!isWorking || definition.disabled}
              />
              <span className="slider"></span>
            </label>
          </div>
        </>
      )
    }
    return <OperationalHours {...inputProps} />
  }

  renderDropdowns = (day: string) => {
    const { definition } = this.props
    const { items } = definition
    const fields = Object.keys(items)

    return fields.map((field, key) => {
      const itemDefinition = items[field]
      return this.renderOperationalHours(day as keyof typeof DAY_LABELS, key + field, field, itemDefinition)
    })
  }

  createInputs = () => {
    const { value: valueList } = this.props
    return Object.keys(valueList).map((day, i) => {
      return (
        <div key={i}>
          <div className="form-input">{this.renderDropdowns(day)}</div>
        </div>
      )
    })
  }

  render() {
    const { formItemClasses } = this.props
    return (
      <>
        <div className="form-item flex pl5">
          <label>
            <span className="form-label w100">{t('staffWorkingHours.workingDays')}</span>
          </label>
          <label>
            <span className="form-label flex offset-12 pl15  w100">{t('staffWorkingHours.edit')}</span>
          </label>
          <label>
            <span className="form-label flex offset-6 pl5 w100">{t('staffWorkingHours.hoursOfOperation')}</span>
          </label>
        </div>
        <div className={classNames('form-item', formItemClasses)}>{this.createInputs()}</div>
      </>
    )
  }
}
