import { Component } from 'react'
import { fromJS } from 'immutable'
import './WorkingHoursMismatchModal.scss'
//@ts-ignore
import Modal from '@humanics/he-react-common/lib/components/Modal_new'
import { updateEventParameters } from '../layoutUtils'
import { curry } from 'i18n'

const t = curry('staff.')

interface IWorkingHoursMismatchModal {
  actionController: any
  workingHoursMismatchDetails: {
    eventShiftId: string
    startTime: string
    endTime: string
    handleSubmit?: () => void
  }
  unitShiftsMap: any
  onCancel: () => void
  eventStaffDetails?: {
    addStaffToOnCallShift?: boolean
  }
  timeService: any
  getStaffEventDetails?: (eventParameters: any) => void
}

export default class WorkingHoursMismatchModal extends Component<IWorkingHoursMismatchModal> {
  onSubmit = () => {
    const {
      actionController,
      workingHoursMismatchDetails,
      unitShiftsMap,
      onCancel,
      eventStaffDetails = {},
      timeService,
      getStaffEventDetails
    } = this.props
    const { eventShiftId, startTime, endTime, handleSubmit } = workingHoursMismatchDetails
    if (handleSubmit) {
      handleSubmit()
      onCancel()
      return
    }
    const shift = unitShiftsMap.get(eventShiftId)
    const eventVariantId = 'assignment'
    let eventParameters = shift.merge(
      {
        eventShiftId,
        eventVariantId,
        shiftId: eventShiftId,
        addStaffToOnCallShift: eventStaffDetails.addStaffToOnCallShift
      },
      fromJS(eventStaffDetails)
    )
    if (startTime && endTime) {
      eventParameters = updateEventParameters(eventParameters, startTime, endTime, timeService)
      getStaffEventDetails?.(eventParameters)
    }
    actionController.createStaffEventsForSelection(eventParameters)
    onCancel()
  }

  render() {
    const { onCancel } = this.props
    return (
      <div className="hx-warning-modal">
        <Modal type="warning">
          <Modal.Title>{t('staffWorkingHoursMismatchConfirmation.title')}</Modal.Title>
          <>
            <Modal.Content>
              <div className="group-item"> {t('staffWorkingHoursMismatchConfirmation.content')}</div>
            </Modal.Content>
            <Modal.Buttons>
              <Modal.OKButton label="Yes" onClick={this.onSubmit} />
              <Modal.CancelButton label="Go Back" onClick={onCancel} />
            </Modal.Buttons>
          </>
        </Modal>
      </div>
    )
  }
}
