import { Component } from 'react'
import { OpenShiftEntity } from 'entityWrappers'
import { navigate } from 'Navigation'
import classNames from 'classnames'
import RemoteStaffRequestDetailsRows from './WeekOpenShifts/OpenShift/RemoteStaffRequestDetailsRows'
import OpenShiftDetailsRow from './OpenShiftDetailsRow'

export default class OpenShiftCard extends Component {
  render() {
    const { openShift, unitRoles, timeService } = this.props
    const openShiftEntity = new OpenShiftEntity(openShift)
    const { createdAtDateTime, time, isPosted, pendingToConfirmUserIds, isFilled, staffMismatch, remoteStaffRequests } =
      openShiftEntity

    const openShiftStatusProps = {
      isPosted,
      pendingToConfirmUserIds,
      isFilled,
      openShift,
      staffMismatch
    }
    const roleId = openShift.get('unitRoleId')
    const isImportant = openShift.get('isImportant')
    const role = unitRoles.find((item) => item.get('id') === roleId)
    const roleName = role?.get('name')
    const shiftName = openShift.get('shiftName')

    const openShiftActivityProps = { openShift }

    const openShiftRowProps = {
      shiftName: shiftName,
      time: time,
      roleName: roleName,
      staffMismatch: staffMismatch,
      createdAtDateTime: createdAtDateTime,
      openShiftStatusProps: openShiftStatusProps,
      openShiftActivityProps: openShiftActivityProps
    }

    return (
      <div
        className={classNames('hx-open-shift-item shift-card row bg-white', {
          'bg-important': !!isImportant
        })}
        onClick={this.navigateToOpenShift(openShiftEntity)}
      >
        <OpenShiftDetailsRow {...openShiftRowProps} />
        <RemoteStaffRequestDetailsRows remoteStaffRequests={remoteStaffRequests} timeService={timeService} />
        <div className="important-icon">
          {!!isImportant && <div className="icon icon-Exclamation-Point red-orange border-redorange" />}
        </div>
      </div>
    )
  }

  navigateToOpenShift = (openShiftEntity) => () => {
    const { isPosted, id, shiftDayId } = openShiftEntity

    if (isPosted) {
      navigate.from.OpenShifts.to.ShiftOpportunity({ id })
    } else {
      navigate.from.OpenShifts.to.OpenShift({ shiftDayId })
    }
  }
}
