import classNames from 'classnames'
import { RemoteRequestState } from 'stores/remoteWorkflowStore/enums/RemoteRequestState'

const getClassNameForRemoteRequest = (remoteRequestState) => {
  return classNames({
    'ufo-green': [RemoteRequestState.ClosedComplete].includes(remoteRequestState),
    dixie: [RemoteRequestState.Open, RemoteRequestState.WorkInProgress].includes(remoteRequestState),
    'red-orange': [RemoteRequestState.Cancelled].includes(remoteRequestState)
  })
}

export default getClassNameForRemoteRequest
