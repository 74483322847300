import { Map, OrderedMap } from 'immutable'
import { findParentElement } from 'utils'
import StateController from './StateController'

const defaultState = Map({
  staffPath: {},
  cells: OrderedMap()
})
export default class PopupController extends StateController {
  get defaultState() {
    return {
      popupConfig: Map({
        popupId: null,
        popupProps: {}
      })
    }
  }

  get popupConfig() {
    return this.state.popupConfig
  }

  get popupProps() {
    return this.popupConfig.get('popupProps')
  }

  get popupId() {
    return this.popupConfig.get('popupId')
  }

  get isPopupVisible() {
    return !!this.popupId
  }

  get isCellDetailsVisible() {
    return this.popupId === 'CellDetails'
  }

  get isRequirementsMismatchVisible() {
    return this.popupId === 'RequirementsMismatchPopup'
  }

  get isWorkingHoursMismatchVisible() {
    return this.popupId === 'WorkingHoursMismatchPopup'
  }

  get isAddStaffToOnCallShiftVisible() {
    return this.popupId === 'ShiftViewContextMenu'
  }

  get isContextMenuVisible() {
    return this.popupId === 'StaffViewContextMenu'
  }

  setEventParameters(eventParameters) {
    const popupProps = this.popupConfig.get('popupProps')
    popupProps.eventParameters = eventParameters
    const popupConfig = this.popupConfig.set('popupProps', popupProps)

    this.setState({ popupConfig })
  }

  onComponentUpdated(prevProps, prevState) {
    const { hasSelectionError } = this.selectedObjects
    const { hasSelectionError: hadSelectionError } = this.getPreviousSelectedObjects(prevState)

    const isSelectionErrorJustAppeared = hasSelectionError && !hadSelectionError

    if (isSelectionErrorJustAppeared) {
      this.hidePopup(false)
    }
  }

  showCellDetailsPopup() {
    this.switchTo('CellDetails')
  }

  showRequirementsMismatchPopup() {
    this.switchTo('RequirementsMismatchPopup')
  }

  showWorkingHoursMismatchPopup = () => {
    this.switchTo('WorkingHoursMismatchPopup')
  }

  updatePopupOnClick(eventMeta = {}) {
    const { isShiftKeyPressed, isMetaKeyPressed, isCtrlKeyPressed } = eventMeta
    const isMultiselectionKeyPressed = isShiftKeyPressed || isMetaKeyPressed || isCtrlKeyPressed

    if (!isMultiselectionKeyPressed) {
      this.hideCellDetails()
    }
    this.hideContextMenu()
    this.hideOnCallAddStaffPopup()
  }

  switchTo = (popupId, eventParameters) => {
    const popupProps = this.popupConfig.get('popupProps')
    if (popupId === 'ShiftViewContextMenu') {
      popupProps.eventParameters = eventParameters
    }
    const popupConfig = this.popupConfig.set('popupId', popupId).set('popupProps', popupProps)

    this.setState({ popupConfig })
  }

  showPopup = (targetElement, view) => {
    const cells = this.state.selection.get('cells')
    const isMultipleCellsSelected = cells.size > 1
    const popupIdForView = view === 'shiftView' ? 'ShiftViewContextMenu' : 'CellDetails'
    const popupId = isMultipleCellsSelected && !this.isRequirementsMismatchVisible ? popupIdForView : this.popupId

    const position = popupId === 'CellDetails' || popupId === 'ShiftViewContextMenu' ? 'top' : 'left'
    const width = popupId === 'CellDetails' ? 380 : 150

    const $staffRow = findParentElement(targetElement, 'classList', '_staff')
    const rect = targetElement.getBoundingClientRect()
    const popupProps = {
      position,
      width,
      targetElement,
      viewPort: {
        top: $staffRow.offsetTop,
        height: targetElement.offsetHeight,
        left: rect.left,
        right: targetElement.offsetLeft + targetElement.offsetWidth
      },
      eventParameters: this.popupProps?.eventParameters
    }

    const popupConfig = this.popupConfig.set('popupProps', popupProps).set('popupId', popupId)

    this.setState({ popupConfig })
  }

  hidePopup = (resetSelection = true) => {
    if (!this.isPopupVisible) {
      return
    }

    const popupConfig = this.popupConfig.merge({ popupId: null })

    const selection = resetSelection ? defaultState : this.state.selection

    this.setState({ popupConfig, selection })
  }

  hideCellDetails = () => {
    this.isCellDetailsVisible && this.hidePopup()
  }

  hideRequirementsMismatch = () => {
    this.isRequirementsMismatchVisible && this.hidePopup()
  }

  hideWorkingHoursMismatch = () => {
    this.isWorkingHoursMismatchVisible && this.hidePopup()
  }

  hideContextMenu = () => {
    this.isContextMenuVisible && this.hidePopup()
  }

  hideOnCallAddStaffPopup() {
    this.isAddStaffToOnCallShiftVisible && this.hidePopup()
  }

  showOpenShiftPopup = (event, roleIndex, shiftIndex, dayIndex) => {
    const targetElement = event.currentTarget
    const isForFixedHeader = findParentElement(targetElement, 'classList', 'hx-grid-header')
    const shiftRow = findParentElement(targetElement, 'classList', '_shift')
    const top = isForFixedHeader ? shiftRow.offsetHeight : shiftRow.offsetTop
    const scrollTop = isForFixedHeader ? 0 : this.state.scrollTop

    const openShiftPopupProps = {
      scrollTop,
      roleIndex,
      shiftIndex,
      dayIndex,
      position: 'top',
      width: 188,
      targetElement,
      viewPort: {
        top,
        height: targetElement.offsetHeight,
        left: targetElement.offsetLeft,
        right: targetElement.offsetLeft + targetElement.offsetWidth
      }
    }

    this.setState({ openShiftPopupProps, popupId: 'OpenShiftPopup' })
  }

  hideOpenShiftPopup = () => {
    this.setState({ popupId: null })

    setTimeout(() => {
      const { popupId } = this.state
      const isOpenShiftPopupInitialized = !!this.component.openShiftPopupRef.current
      const isOpenShiftPopupHovered =
        isOpenShiftPopupInitialized && this.component.openShiftPopupRef.current.isHovered()
      const isOpenShiftPopupVisible = popupId === 'OpenShiftPopup'

      if (isOpenShiftPopupInitialized && !isOpenShiftPopupHovered && !isOpenShiftPopupVisible) {
        this.setState({ openShiftPopupProps: null })
      }
    }, 150)
  }
}
