import { Component } from 'react'
import OperationalHours from './OperationalHours'
import WeekDetailsComponent from './WeekDetails'

interface IOperationalDetailItems {
  label: string
  format: string
  inputType: string
}

interface IDefaultDetails {
  label: string
  inputType: string
  items: Record<string, IOperationalDetailItems>
}

interface IWeekDetailsItems {
  isWorking: {
    type: string
    default: boolean
  }
  operationalHours: IDefaultDetails
}

interface IWeekDetails {
  label: string
  inputType: string
  dependsOnFormData: boolean
  items: IWeekDetailsItems
}

interface IWorkingHoursProps {
  value: any
  onChange: (form: any) => void
}

interface IWorkingHoursState {
  form: any
  formData: any
  errors: any
}

const operationalDetailItems: Record<string, IOperationalDetailItems> = {
  startTime: {
    label: 'Start Time',
    format: 'time',
    inputType: 'InputTime'
  },
  endTime: {
    label: 'End Time',
    format: 'time',
    inputType: 'InputTime'
  }
}

const defaultDetails: IDefaultDetails = {
  label: 'Default Shift Time',
  inputType: 'InputOperationalHours',
  items: operationalDetailItems
}

const weekDetails: IWeekDetails = {
  label: 'Custom Operational Setting',
  inputType: 'InputWeekDetails',
  dependsOnFormData: true,
  items: {
    isWorking: {
      type: 'boolean',
      default: false
    },
    operationalHours: {
      label: 'operationalHours',
      inputType: 'InputOperationalHours',
      items: operationalDetailItems
    }
  }
}

export default class WorkingHours extends Component<IWorkingHoursProps, IWorkingHoursState> {
  constructor(props: IWorkingHoursProps) {
    super(props)
    this.state = {
      form: props.value,
      formData: {},
      errors: {}
    }
  }

  onInputChange = (field: string, value: any) => {
    this.setState(
      (prevState) => ({
        form: { ...prevState.form, [field]: value }
      }),
      () => {
        const { onChange } = this.props
        onChange(this.state.form)
      }
    )
  }

  setFormData = (data: any) => {
    this.setState((prevState) => ({
      formData: { ...prevState.formData, ...data }
    }))
  }

  render() {
    const { form, errors, formData } = this.state
    return (
      <>
        <OperationalHours
          value={form?.defaultDetails}
          onChange={(value) => this.onInputChange('defaultDetails', value)}
          model="defaultDaySettings"
          field="defaultDetails"
          formItemClasses="offset-4"
          error={errors.defaultDetails}
          definition={defaultDetails}
          formData={formData}
          setFormData={this.setFormData}
        />

        <WeekDetailsComponent
          value={form?.weekDetails}
          onChange={(value) => this.onInputChange('weekDetails', value)}
          error={errors.weekDetails}
          model="defaultDaySettings"
          definition={weekDetails}
          formData={formData}
          setFormData={this.setFormData}
        />
      </>
    )
  }
}
