import { Component, createRef } from 'react'
import OpenShiftCard from './OpenShiftCard'
import WeekOpenShifts from './WeekOpenShifts'
import './OpenShiftCards.scss'
import OpenShiftCardHeader from './OpenShiftCardHeader'
import OpenShiftsStatusBar from './OpenShiftsStatusBar'
import { curry } from 'i18n'
import stickybits from 'stickybits'

const t = curry('openShifts.cards.')

export default class OpenShiftCards extends Component {
  constructor(props) {
    super(props)
    this.state = { weekExpand: {} }
    this.ref = createRef()
  }

  componentDidMount() {
    const { todayOpenShifts, tomorrowOpenShifts, restOpenShifts } = this.props
    const isTodayOpenShiftLoaded = todayOpenShifts.size > 0
    const isTomorrowOpenShiftsLoaded = tomorrowOpenShifts.size > 0
    const isRestOpenShiftsLoaded = restOpenShifts.size > 0
    const offsetTop = this.ref.current && this.ref.current.offsetTop - 2

    if (isTodayOpenShiftLoaded && this.ref.current) {
      stickybits('.today-open-shifts-header', {
        stickyBitStickyOffset: offsetTop
      })
    }

    if (isTomorrowOpenShiftsLoaded && this.ref.current) {
      stickybits('.tomorrow-open-shifts-header', {
        stickyBitStickyOffset: offsetTop
      })
    }

    if (isRestOpenShiftsLoaded && this.ref.current) {
      stickybits('.future-open-shifts-header', {
        stickyBitStickyOffset: offsetTop
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { todayOpenShifts, tomorrowOpenShifts, restOpenShifts } = this.props
    const {
      todayOpenShifts: prevTodayOpenShifts,
      tomorrowOpenShifts: prevTomorrowOpenShifts,
      restOpenShifts: prevRestOpenShifts
    } = prevProps

    const isTodayOpenShiftLoaded = prevTodayOpenShifts.size === 0 && todayOpenShifts.size > 0
    const isTomorrowOpenShiftsLoaded = prevTomorrowOpenShifts.size === 0 && tomorrowOpenShifts.size > 0
    const isRestOpenShiftsLoaded = prevRestOpenShifts.size === 0 && restOpenShifts.size > 0
    const offsetTop = this.ref.current && this.ref.current.offsetTop - 2

    if (isTodayOpenShiftLoaded && offsetTop) {
      stickybits('.today-open-shifts-header', {
        stickyBitStickyOffset: offsetTop
      })
    }

    if (isTomorrowOpenShiftsLoaded && offsetTop) {
      stickybits('.tomorrow-open-shifts-header', {
        stickyBitStickyOffset: offsetTop
      })
    }

    if (isRestOpenShiftsLoaded && offsetTop) {
      stickybits('.future-open-shifts-header', {
        stickyBitStickyOffset: offsetTop
      })
    }
  }

  render() {
    const {
      unitRoles,
      todayOpenShifts,
      tomorrowOpenShifts,
      restOpenShifts,
      facilityUsersMap,
      timeService,
      acceptanceRate,
      totalOpenShifts,
      totalTodayOpenShifts,
      totalShiftOpportunities
    } = this.props

    const { weekExpand } = this.state

    const restOpenShiftsKeys = restOpenShifts.keySeq()
    const todayMoment = timeService.timeMoment(null)
    const todayLabel = todayMoment.format('MMM D')
    const tomorrowLabel = todayMoment.add(1, 'days').format('MMM D')

    return (
      <div ref={this.ref}>
        <div className="hx-open-shift-card-group">
          <OpenShiftsStatusBar
            acceptanceRate={acceptanceRate}
            totalOpenShifts={totalOpenShifts}
            totalTodayOpenShifts={totalTodayOpenShifts}
            totalShiftOpportunities={totalShiftOpportunities}
          />
        </div>
        <div className="hx-open-shift-card-group">
          <CardHeader translationKey={'header.today'} date={todayLabel} />
          {todayOpenShifts.size > 0 ? (
            <div className="hx-open-shifts-table-body relative">
              <OpenShiftCardHeader classNames="today-open-shifts-header" />
              {todayOpenShifts.map((openShift, key) => (
                <OpenShiftCard key={key} openShift={openShift} unitRoles={unitRoles} timeService={timeService} />
              ))}
            </div>
          ) : (
            <EmptyCard translationKey={'empty.today'} />
          )}
        </div>

        <div className="hx-open-shift-card-group">
          <CardHeader translationKey={'header.tomorrow'} date={tomorrowLabel} />
          {tomorrowOpenShifts.size > 0 ? (
            <div className="hx-open-shifts-table-body relative">
              <OpenShiftCardHeader classNames="tomorrow-open-shifts-header" />
              {tomorrowOpenShifts.valueSeq().map((openShift, key) => (
                <OpenShiftCard key={key} openShift={openShift} unitRoles={unitRoles} timeService={timeService} />
              ))}
            </div>
          ) : (
            <EmptyCard translationKey={'empty.tomorrow'} />
          )}
        </div>
        <div className="hx-open-shift-card-group">
          <CardHeader translationKey={'header.future'} />
          {restOpenShifts.size > 0 ? (
            <div className="hx-open-shifts-body">
              <OpenShiftCardHeader classNames="future-open-shifts-header" />
              {restOpenShifts.valueSeq().map((openShifts, key) => {
                const weekFromNow = openShifts.first().getIn([0, 'weekFromNow'])

                return (
                  <WeekOpenShifts
                    key={key}
                    unitRoles={unitRoles}
                    weekFromNow={restOpenShiftsKeys.get(key)}
                    openShifts={openShifts}
                    facilityUsersMap={facilityUsersMap}
                    toggle={() => this.toggleWeekOpenShifts(weekFromNow)}
                    isExpanded={weekExpand[weekFromNow]}
                    timeService={timeService}
                  />
                )
              })}
            </div>
          ) : (
            <EmptyCard translationKey={'empty.future'} />
          )}
        </div>
      </div>
    )
  }

  toggleWeekOpenShifts = (weekFromNow) => {
    this.setState(({ weekExpand }) => {
      weekExpand[weekFromNow] = !weekExpand[weekFromNow]
      return { weekExpand }
    })
  }
}

function EmptyCard({ translationKey }) {
  return (
    <div className="hx-open-shift-item row align-middle bg-white hx-open-shift-empty">
      <sh-text size="body-1">{t(translationKey)}</sh-text>
    </div>
  )
}

function CardHeader({ translationKey, date }) {
  return (
    <header className="hx-open-shift-cards-header">
      <sh-text size="header-2">{t(translationKey, { date })}</sh-text>
    </header>
  )
}
