import { t } from 'i18n'

import RemoteWeScanStaffRequestController from '../RemoteWeScanStaffRequestController'
import { IRemoteStaffRequest, IWeScanAppointment } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'
import { WeScanAppointmentState } from 'stores/remoteWorkflowStore/enums/WeScanAppointmentState'

interface IRemoteWeScanRequestAppointmentRowProps {
  appointment: IWeScanAppointment
  remoteStaffRequest: IRemoteStaffRequest

  controller: RemoteWeScanStaffRequestController
  handleCancelRequestButtonClick: (_remoteStaffRequest: IRemoteStaffRequest, _appointment: IWeScanAppointment) => void
  index: number
}

export default function RemoteWeScanRequestAppointmentRow(props: IRemoteWeScanRequestAppointmentRowProps) {
  const { appointment, index, remoteStaffRequest, controller } = props

  const startTime = controller.formatAsTime(appointment.startsAt)
  const endTime = controller.formatAsTime(appointment.endsAt)

  const isPastCurrentTime = controller.isRequestPastCurrentTime(remoteStaffRequest)
  const canRequestBeCancelled = controller.canRemoteWeScanRequestBeCancelled(remoteStaffRequest)
  const canAppointmentBeCancelled =
    canRequestBeCancelled &&
    appointment.state !== WeScanAppointmentState.Cancelled &&
    appointment.state !== WeScanAppointmentState.Closed

  const handleCancelRequestButtonClick = (e: MouseEvent) => {
    e.stopPropagation()
    props.handleCancelRequestButtonClick(remoteStaffRequest, appointment)
  }

  return (
    <div className="row bg-white child-row">
      <div></div>
      <div></div>
      <div>
        <span className="bold">{index + 1}.</span>
        &nbsp;&nbsp;
        <span>
          {startTime} to {endTime}
        </span>
      </div>
      <div>{appointment.state}</div>
      <div></div>

      <div className="actions">
        {canAppointmentBeCancelled && (
          <>
            <div id="cancelIconContainer">
              <sh-icon
                class="regent-gray"
                icon="cancel"
                disabled={isPastCurrentTime ? true : undefined}
                onClick={handleCancelRequestButtonClick}
              ></sh-icon>
            </div>
            <sh-tooltip
              label={
                isPastCurrentTime
                  ? t('equipments.request_cannot_be_cancelled_message')
                  : t('equipments.cancel_wescan_appointment_text')
              }
              target="cancelIconContainer"
              placement="top"
              variation="short"
            ></sh-tooltip>
          </>
        )}
      </div>
    </div>
  )
}
