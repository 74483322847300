import { SpillProofDiv } from 'Common/components'

export default function Item({
  title,
  onClick,
  isCheckbox = false,
  onMouseEnter,
  isChecked,
  className = '',
  hasSubmenu = false,
  iconClassName = false,
  isCollapsible = false,
  expanded = false
}) {
  className = `context__item ${className}`

  const getCheckbox = (title) => {
    if (isChecked) {
      return <sh-checkbox label={title} checkbox active={true} />
    } else {
      return (
        <sh-checkbox
          label={title}
          checkbox
          active={() =>
            setTimeout(() => {
              return undefined
            }, 10)
          }
        />
      )
    }
  }

  return (
    <div className={className} role="presentation" onClick={onClick}>
      <div className="context__item__data" onMouseEnter={onMouseEnter}>
        {isCheckbox && getCheckbox(title)}
        {iconClassName && <i className={iconClassName} />}
        {!isCheckbox && (
          <SpillProofDiv id={title} className="mr15">
            {title}
          </SpillProofDiv>
        )}
        {hasSubmenu && (
          <span className="context__submenu-arrow">
            <i className="icon-right rock-blue" />
          </span>
        )}
        {isCollapsible && (
          <span className="context__submenu-arrow">
            <i className={`rock-blue ${expanded ? 'icon-up' : 'icon-down'}`} />
          </span>
        )}
      </div>
    </div>
  )
}
