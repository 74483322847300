import { List } from 'immutable'

export default class CalendarFilterService {
  constructor(filters) {
    this.filters = filters
  }

  isRoleVisible = (role) => {
    const hideEmptyRoles = this.filters.get('hideEmptyRoles')
    if (hideEmptyRoles && this.isRoleEmpty(role)) {
      return false
    }

    const roleId = role.get('id')
    const isSecondaryStaff = roleId === 'secondary-staff'
    const hideSecondaryStaff = this.filters.get('hideSecondaryStaff')

    if (isSecondaryStaff) {
      return !hideSecondaryStaff
    }

    const filterRoleIds = this.filters.get('roleIds') || List()
    let roleShortName = role.get('id')

    //fix invalid response from server
    roleShortName = roleShortName ? roleShortName.toUpperCase() : roleShortName

    return filterRoleIds.includes(roleShortName)
  }

  isShiftVisible = (shift, hideOnCall) => {
    if (
      this.filters.get('resourceTypes') &&
      shift.get('resourceType') &&
      !this.filters.get('resourceTypes').includes(shift.get('resourceType'))
    ) {
      return false
    }
    if (shift.get('isOnCall')) {
      return !hideOnCall
    }
    if (shift.get('isHidden')) {
      return false
    }
    const hideEmptyShifts = this.filters.get('hideEmptyShifts')
    if (hideEmptyShifts && this.isShiftEmpty(shift)) {
      return false
    }

    const hideEmptyShiftsWithoutOpenShifts = this.filters.get('hideEmptyShiftsWithoutOpenShifts')
    if (hideEmptyShiftsWithoutOpenShifts && this.isShiftEmpty(shift) && !this.hasOpenShift(shift)) {
      return false
    }

    const shiftId = shift.get('id')
    const isSecondaryStaff = shiftId === 'secondary-staff-shift'
    const hideSecondaryStaff = this.filters.get('hideSecondaryStaff')

    if (isSecondaryStaff) {
      return !hideSecondaryStaff
    }

    const filterShiftTypes = this.filters.get('shiftTypes') || List()
    const shiftType = shift.get('typeId')

    return filterShiftTypes.includes(shiftType)
  }

  isStaffVisible = (staff) => {
    const staffFilter = (this.filters.get('filterBySearch') || '').toLowerCase().trim()
    return CalendarFilterService.filterUserBySearch(staff, staffFilter)
  }

  isRoleEmpty = (role) => {
    const roleShifts = role.get('shifts').filter(this.isShiftVisible)
    return roleShifts.size === 0
  }

  isShiftEmpty = (shift) => {
    const shiftStaff = shift.get('staff').filter(this.isStaffVisible)
    return shiftStaff.size === 0
  }

  hasOpenShift = (shift) => {
    const shiftDay = shift.get('shiftDay')
    const openShift = shiftDay.get('openShift')
    const shiftOpportunity = shiftDay.get('shiftOpportunity')
    return !!shiftOpportunity || !!openShift
  }

  static filterUserBySearch(user, searchFilter) {
    if (!searchFilter || searchFilter.length === 0) {
      return true
    }

    const profile = user.get('profile')
    const fullNameSearchable = user.get('fullNameAsSearchable')
    const firstName = profile.get('firstName')
    const lastName = profile.get('lastName')
    const lowerName = firstName.toLowerCase()
    const lowerLastName = lastName.toLowerCase()
    const lowerFullName = fullNameSearchable.toLowerCase()

    const id = user.get('id')

    return (
      lowerName.startsWith(searchFilter) ||
      lowerLastName.startsWith(searchFilter) ||
      lowerFullName.startsWith(searchFilter) ||
      id === searchFilter
    )
  }
}
