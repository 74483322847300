import { PureComponent } from 'react'
import Mismatch from './Mismatch'
import { OpenShiftEntity } from 'entityWrappers'
import { getStaffStrategyProperties } from 'utils'
import { Map } from 'immutable'

export default class HeaderDay extends PureComponent {
  componentWillUnmount() {
    const { hideOpenShiftPopup, openShiftPopupProps, shift } = this.props
    const { roleIndex: openShiftRoleIndex, shiftIndex: openShiftShiftIndex } = openShiftPopupProps || {}
    const shiftIndex = shift.get('shiftIndex')
    const roleIndex = shift.get('roleIndex')

    if (shiftIndex === openShiftShiftIndex && roleIndex === openShiftRoleIndex) {
      hideOpenShiftPopup()
    }
  }

  render() {
    const { activeDateRange, showScheduledNumbers, shift } = this.props

    const hours = 24
    const width = `${100 / hours}%`
    const hoursArray = this.calculateHoursTime()
    const state = activeDateRange.get('state')
    const isStaffView = shift?.get('viewPreference') === 'staff'
    const showMismatches = !showScheduledNumbers && state !== 'inactive' && isStaffView

    return (
      <section className="col-10" style={{ lineHeight: 29 + 'px' }}>
        <div className="row text-center bl4">
          <div className="hours-list-container">
            {showMismatches && hoursArray.map(this.renderMismatch(shift, width))}
          </div>
        </div>
      </section>
    )
  }

  calculateHoursTime = () => {
    const { shift, timeService, hours = 24, filters } = this.props
    const shiftDay = shift.get('shiftDay')
    const showMergedShift = filters.get('showMergedShift')
    const hoursArray = Array(hours).fill(null)

    if (!shiftDay) {
      return hoursArray
    }

    const firstShiftDay = shiftDay?.first()
    const shiftDuration = firstShiftDay?.get('length')
    const startsAt = firstShiftDay?.get('startsAt')
    const endsAt = firstShiftDay?.get('endsAt')
    const staffMismatch = firstShiftDay?.get('staffMismatch')
    const minimumCoverageRequired = firstShiftDay?.get('minimumCoverageRequired')
    const maximumCoverageRequired = firstShiftDay?.get('maximumCoverageRequired')
    const standardFilledHour = new Map({
      staffMismatch,
      minimumCoverageRequired,
      maximumCoverageRequired
    })
    if (showMergedShift) {
      hoursArray.fill(standardFilledHour)
    } else if (startsAt && endsAt && shiftDuration) {
      const startsAtHours = timeService.timeMoment(startsAt).hours()
      const startsAtMinutes = timeService.timeMoment(startsAt).minutes()
      let endsAtHours = timeService.timeMoment(endsAt).hours()
      const endsAtMinutes = timeService.timeMoment(endsAt).minutes()
      const isTwoDayShift = startsAtHours > endsAtHours
      let startsAtStaffMismatch = staffMismatch
      let startsAtMinimumCoverageRequired = minimumCoverageRequired
      let startsAtMaximumCoverageRequired = maximumCoverageRequired
      if (isTwoDayShift) {
        hoursArray.fill(standardFilledHour, 0, endsAtHours)
        const secondShiftDay = shiftDay?.get(1)
        startsAtStaffMismatch = secondShiftDay?.get('staffMismatch')
        startsAtMinimumCoverageRequired = secondShiftDay?.get('minimumCoverageRequired')
        startsAtMaximumCoverageRequired = secondShiftDay?.get('maximumCoverageRequired')
        hoursArray.fill(
          new Map({
            staffMismatch: startsAtStaffMismatch,
            minimumCoverageRequired: startsAtMinimumCoverageRequired,
            maximumCoverageRequired: startsAtMaximumCoverageRequired
          }),
          startsAtHours,
          24
        )
      } else {
        hoursArray.fill(standardFilledHour, startsAtHours, endsAtHours)
      }

      const startFraction = 1 - startsAtMinutes / 60
      hoursArray[startsAtHours] = new Map({
        staffMismatch: startsAtStaffMismatch,
        minimumCoverageRequired: startsAtMinimumCoverageRequired && startFraction * startsAtMinimumCoverageRequired,
        maximumCoverageRequired: startsAtMaximumCoverageRequired && startFraction * startsAtMaximumCoverageRequired
      })
      if (endsAtMinutes > 0) {
        const endFraction = endsAtMinutes / 60
        hoursArray[endsAtHours] = new Map({
          staffMismatch: staffMismatch,
          minimumCoverageRequired: minimumCoverageRequired && endFraction * minimumCoverageRequired,
          maximumCoverageRequired: maximumCoverageRequired && endFraction * maximumCoverageRequired
        })
      }
    }
    return hoursArray
  }

  renderMismatch(shift, width) {
    const { filters } = this.props
    const strategy = filters.getIn(['displayOptions', 'coverage', 'viewCoverageAs'])
    const shiftMismatch = shift.get('shiftMismatch')
    return (shiftHour, hourIndex) => {
      if (!shiftHour) {
        return (
          <div
            key={hourIndex}
            width={width}
            className={'hour-container text-center trout upper black br2 border-periwinklegray'}
          />
        )
      }
      const shiftId = shift.get('id')
      const shiftHourId = shiftHour?.get('id')
      const roleId = shift.get('unitRoleId')
      const openShiftKey = OpenShiftEntity.buildKey(shiftId, shiftHourId)

      const staffRequirement = {
        min: shiftHour?.get('minimumCoverageRequired'),
        max: shiftHour?.get('maximumCoverageRequired')
      }

      const staffScheduled = shiftMismatch?.[hourIndex] || 0

      const { count, className } = getStaffStrategyProperties(strategy, staffScheduled, staffRequirement)

      const mismatchParams = {
        roleId,
        shiftId,
        count: Math.abs(count),
        hourIndex
      }

      return (
        <div
          key={hourIndex}
          width={width}
          data-open-shift-key={openShiftKey}
          className={`hour-container text-center trout upper black br2 border-periwinklegray _headerCell _staffMismatch border-mystic ${className}`}
          style={{ height: 'auto' }}
        >
          <Mismatch {...mismatchParams} />
        </div>
      )
    }
  }
}
