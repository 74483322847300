import StateController from './StateController'
import { findParentElement } from 'utils'
import { canCreateShiftAssignment } from './Rules'
import { getAppropriateStore, shouldShowWorkingHoursMismatchPopup, getDateTimeDetails } from '../layoutUtils'
import { remoteServiceUserIds } from 'utils/remoteServiceUserIds'
import { StaffWorkingHoursService, ShiftService } from 'services'
import { updateEventParameters } from '../layoutUtils'

export default class CalendarEventController extends StateController {
  onClickOutsideTheGrid = (event) => {
    const { target: targetElement } = event

    const isTargetElementInsideGrid = targetElement && !!findParentElement(targetElement, 'id', 'hx-calendar')

    const isTargetElementInsidePopup = targetElement && !!findParentElement(targetElement, 'classList', 'popup')

    if (!isTargetElementInsideGrid && !isTargetElementInsidePopup) {
      this.component.hidePopup()
      this.component.resetSelection()
    }
  }

  onCellEvent = (staffPath, cellIndex, eventType, meta) => {
    const isShiftLoaded = this.calendar.isShiftLoaded(staffPath)

    if (!isShiftLoaded) {
      return
    }

    switch (eventType) {
      case 'onMouseOver':
        return this._onCellMouseOver(staffPath, cellIndex, meta)
      case 'onMouseLeave':
        return this._onCellMouseLeave(staffPath, cellIndex, meta)
      case 'onDoubleClick':
        return this._onCellDoubleClick(staffPath, cellIndex, meta)
      case 'onClick':
        return this._onCellClick(staffPath, cellIndex, meta)
      case 'onContextMenu':
        return this._onCellContextMenu(staffPath, cellIndex, meta)
      default:
        throw new Error('cell event was invoked with unknown eventType: ' + eventType)
    }
  }

  _onCellMouseOver = (staffPath, cellIndex, meta) => {
    this.component.cellTooltipController.updateCellTooltip({ staffPath, cellIndex, meta })
  }

  _onCellMouseLeave = (staffPath, cellIndex, meta) => {
    this.component.cellTooltipController.hideCellTooltip()
  }

  _onCellClick = (staffPath, cellIndex, meta) => {
    const cell = this.calendar.getStaffCell({ ...staffPath, cellIndex })
    const isDummyCell = cell?.get('isDummy')
    if (isDummyCell) {
      return
    }

    this.component.multiselectController.handleClick(staffPath, cellIndex, meta)
    if (cell?.get('isAvatarCell')) {
      const { eventMeta } = meta
      const { isShiftKeyPressed, isMetaKeyPressed, isCtrlKeyPressed } = eventMeta
      if (isShiftKeyPressed || isMetaKeyPressed || isCtrlKeyPressed) {
        const { shift } = this.calendar.getCellDetails(staffPath, cellIndex)
        const shiftId = shift.get('id')
        const eventVariantId = 'assignment'
        const eventParameters = shift.merge({
          shiftId,
          eventVariantId,
          addStaffToOnCallShift: true
        })
        this.component.popupController.setEventParameters(eventParameters)
      } else {
        this.component.popupController.hidePopup()
      }
      return
    }
    this.component.popupController.updatePopupOnClick(meta.eventMeta)
    this.component.cellTooltipController.updateCellTooltip({ staffPath, cellIndex, meta })
  }

  _onCellContextMenu = (staffPath, cellIndex, meta) => {
    const cell = this.calendar.getStaffCell({ ...staffPath, cellIndex })
    const isDummyCell = cell.get('isDummy')
    if (isDummyCell) {
      return
    }

    const { identityHash, cellSubIndex } = meta
    this.component.popupController.hidePopup()
    this.component.multiselectController.selectSingleCell(staffPath, cellIndex, {
      identityHash,
      cellSubIndex
    })
    let eventParameters
    if (cell.get('isAvatarCell')) {
      const { shift } = this.calendar.getCellDetails(staffPath, cellIndex)
      const shiftId = shift.get('id')
      const eventVariantId = 'assignment'
      eventParameters = shift.merge({
        shiftId,
        eventVariantId,
        addStaffToOnCallShift: true
      })
    }
    this.component.popupController.switchTo('StaffViewContextMenu', eventParameters)
  }

  _onCellDoubleClick = async (staffPath, cellIndex, meta) => {
    const { calendarType, timeService } = this.props
    let store = getAppropriateStore(calendarType)
    const { identityHash, cellSubIndex } = meta
    const cell = this.calendar.getStaffCell({ ...staffPath, cellIndex })
    const isRemoteStaff = remoteServiceUserIds.includes(cell.get('staff'))
    const isDummyCell = cell?.get('isDummy')
    if (isRemoteStaff || isDummyCell) {
      return
    }

    const { shift, staff, day } = this.calendar.getCellDetails(staffPath, cellIndex)
    let isOtherStaffShift = shift.get('id') === 'secondary-staff-shift'
    const isShiftViewCell = cell?.get('isAvatarCell')
    const hasEvent = cell?.get('staffEvents')?.size > 0

    if (!isShiftViewCell) {
      if (!hasEvent) {
        this.component.getStaffEventDetails?.({})
      }
      if (isOtherStaffShift && !hasEvent) {
        this.component.popupController.switchTo('SecondaryStaffWarning')
        return
      }
      const mismatchHandled = await this._handleWorkingHoursMismatch(
        cell,
        shift,
        staff,
        day,
        staffPath,
        cellIndex,
        identityHash,
        cellSubIndex,
        timeService
      )
      if (mismatchHandled) return

      const respondToDoubleClick = hasEvent || (await canCreateShiftAssignment(this, staffPath, cellIndex, store))
      if (!respondToDoubleClick) {
        return
      }
    } else if (!hasEvent) {
      this.component.multiselectController.selectSingleCell(staffPath, cellIndex, {
        identityHash,
        cellSubIndex
      })
      const shiftId = shift.get('id')
      const eventVariantId = 'assignment'
      const eventParameters = shift.merge({
        shiftId,
        eventVariantId,
        addStaffToOnCallShift: true
      })
      this.component.popupController.hidePopup()
      this.component.popupController.switchTo('StaffViewContextMenu', eventParameters)
      return
    }

    this.component.multiselectController.selectSingleCell(
      staffPath,
      cellIndex,
      { identityHash },
      this.getDoubleClickCallback(cell, shift)
    )
  }

  _handleWorkingHoursMismatch = async (
    cell,
    shift,
    staff,
    day,
    staffPath,
    cellIndex,
    identityHash,
    cellSubIndex,
    timeService
  ) => {
    const hasEvent = cell?.get('staffEvents')?.size > 0
    if (hasEvent) return false

    const staffService = new StaffWorkingHoursService(staff)

    const { dateTime, currentDay } = getDateTimeDetails(day, timeService)
    const { startTime, endTime } = staffService.getWorkingHoursPeriodForDay(currentDay)

    const shiftService = new ShiftService(shift, timeService)
    const { shiftStartTime, shiftEndTime } = shiftService.getShiftStartEndTime()

    if (startTime && endTime) {
      if (
        shouldShowWorkingHoursMismatchPopup(shiftStartTime, shiftEndTime, startTime, endTime, dateTime, timeService)
      ) {
        this.component.multiselectController.selectSingleCell(staffPath, cellIndex, { identityHash })
        this.component.setWorkingHoursMismtachDetails(null, shift.get('id'), startTime, endTime)
        this.component.popupController.showWorkingHoursMismatchPopup()
      } else {
        const eventParameters = updateEventParameters(
          shift.merge({ shiftId: shift.get('id'), eventVariantId: 'assignment' }),
          startTime,
          endTime,
          timeService
        )
        this.component.getStaffEventDetails?.(eventParameters)
        this.component.multiselectController.selectSingleCell(staffPath, cellIndex, { identityHash }, () => {
          this.component.actionController.createStaffEventsForSelection(eventParameters)
        })
      }
      return true
    }

    return false
  }

  getDoubleClickCallback = (cell, shift) => () => {
    const hasMultiShiftEvents = cell.get('staffEvents')?.size > 1
    if (hasMultiShiftEvents) {
      return
    }
    const hasEvent = cell.get('staffEvents')?.size > 0
    if (hasEvent) {
      this.component.actionController.deleteSelectedStaffEvents()
    } else {
      const shiftId = shift.get('id')
      const eventVariantId = 'assignment'
      const eventParameters = shift.merge({ shiftId, eventVariantId })
      this.component.actionController.createStaffEventsForSelection(eventParameters)
    }
    this.component.multiselectController.cancelMultiselect()
    this.component.popupController.hidePopup()
  }
}
