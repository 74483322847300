import classNames from 'classnames'
import { curry } from 'i18n'

const t = curry('openShifts.cards.status.')

function Status({ label }) {
  return <>{label}</>
}

export default function OpenShiftStatus({
  isPosted,
  pendingToConfirmUserIds,
  isFilled,
  openShift,
  staffMismatch,
  columnLabel = true
}) {
  const shiftId = openShift?.get('shiftId')
  const shiftDayId = openShift?.get('shiftDayId')
  const label = labelText()
  const idTooltip = `${shiftId}-${shiftDayId}`

  return (
    <>
      <div
        id={idTooltip}
        className={classNames('upper fs-10 black', {
          'col-3': columnLabel,
          'ufo-green': isFilled,
          casper: !isPosted,
          dixie: isPosted && pendingToConfirmUserIds.size === 0 && staffMismatch > 0,
          'dodger-blue': !isFilled && pendingToConfirmUserIds.size > 0
        })}
        data-testid="open-shift-status"
      >
        <Status label={label} />
      </div>
      <sh-tooltip label={label?.toUpperCase()} target={idTooltip} placement="bottom" variation="short" />
    </>
  )

  function labelText() {
    let label = ''
    if (isFilled) {
      label = t('filled')
    } else if (!isPosted) {
      label = t('nobodyInvited')
    } else if (pendingToConfirmUserIds.size === 0 && staffMismatch !== 0) {
      const daysSinceCreation = Math.abs(openShift.get('daysSinceCreation'))
      if (daysSinceCreation === 0) {
        const date = openShift.get('createdAtTime')
        label = t('today', { date })
      } else {
        label = t('posted.noAcceptanceYet', { date: daysSinceCreation })
      }
    } else {
      label = t('posted.acceptanceComplete')
    }
    return label
  }
}
