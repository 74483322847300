export default class StaffWorkingHoursService {
  constructor(staff) {
    this.staff = staff
  }

  getWorkingHoursForDay(day) {
    return this.staff.getIn(['staffProfile', 'workingHours', 'weekDetails', day, 'operationalHours'])
  }

  getWorkingHoursPeriodForDay(day) {
    const workingHours = this.getWorkingHoursForDay(day)
    if (workingHours) {
      return {
        startTime: workingHours.get('startTime'),
        endTime: workingHours.get('endTime')
      }
    }
    return {}
  }
}

window.StaffWorkingHoursService = StaffWorkingHoursService
