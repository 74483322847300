import { fromJS, Map } from 'immutable'
import { avatar } from '../helpers'
import { staffQuery, staffOnlyQuery } from '../Queries'
import {
  appendStaffEligibleUnitsItemMutation,
  createFacilityUserMutation,
  inviteFacilityUserMutation,
  revokeFacilityUserInvitationsMutation,
  revokeFacilityUserPermissionsMutation,
  terminateStaffMutation,
  updateFacilityUserFacilityProfileMutation,
  updateMyFacilityUserFacilityProfileMutation,
  updateStaffProfileMutation,
  popStaffEligibleUnitsItemMutation,
  updateStaffProfileExpertiseMutation
} from '../Mutations'

const DEFAULT_LIMIT = 9999999

function Staff() {
  let instanceUri = null
  let setStaff = null
  let gqlClient = null
  let timeService = null
  let updateStaffInList = null

  const actions = {
    resetStaff,
    loadStaff,
    resendInvitation,
    revokePermissions,
    inviteFacilityUser,
    createFacilityUser,
    updateFacilityUser,
    acceptStaffTransfer,
    cancellStaffTransfer,
    initiateStaffTransfer,
    appendStaffEligibleUnit,
    updateStaffProfileExpertise
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ setStaff, gqlClient, updateStaffInList, instanceUri, facilityTime: timeService } = context)
  }

  async function resendInvitation(parameters) {
    const { userId } = parameters
    await revokeFacilityUserInvitations({ userId })
    await inviteFacilityUser({ parameters })
  }

  async function inviteFacilityUser({ parameters }) {
    const { userId } = parameters

    await gqlClient.mutate(inviteFacilityUserMutation, { parameters })

    const userProperties = {
      hasPermissions: false,
      isInvitationSent: true,
      isInvitationAccepted: false
    }
    return updateStaffInList(userId, userProperties)
  }

  async function revokeFacilityUserInvitations(parameters) {
    const { userId } = parameters

    await gqlClient.mutate(revokeFacilityUserInvitationsMutation, parameters)

    const userProperties = {
      hasPermissions: false,
      isInvitationSent: false,
      isInvitationAccepted: false
    }
    return updateStaffInList(userId, userProperties)
  }

  async function revokePermissions(parameters) {
    const { userId } = parameters

    await gqlClient.mutate(revokeFacilityUserPermissionsMutation, parameters)

    const userProperties = {
      hasPermissions: false,
      isInvitationSent: false,
      isInvitationAccepted: false
    }
    return updateStaffInList(userId, userProperties)
  }

  function resetStaff() {
    return setStaff(Map())
  }

  async function loadStaff(userId, isStaffOnly) {
    const days = 90
    const todayMoment = timeService.timeMoment(null).add(1, 'day').startOf('day')
    const startDate = todayMoment.clone().add(-days, 'days').toISOString()
    const endDate = todayMoment.toISOString()

    let staff = null
    let extensionParameters = {
      staffComplianceRules: null,
      staffRecurringSchedules: null,
      notes: null
    }
    if (isStaffOnly) {
      const { myFacilityUser } = await gqlClient.query(staffOnlyQuery)
      staff = extendFacilityUser(myFacilityUser, extensionParameters)
    } else {
      const parameters = {
        userId,
        endDate,
        startDate,
        limit: DEFAULT_LIMIT
      }
      const {
        facilityUser,
        notesByDateRange: notes,
        staffComplianceRules: complianceRules,
        staffRecurringSchedules: recurringSchedules
      } = await gqlClient.query(staffQuery, parameters)

      const staffComplianceRules = complianceRules.filter((rule) => rule.userIds.includes(userId))
      const staffRecurringSchedules = recurringSchedules.filter((schedule) => schedule.userId === userId)
      extensionParameters = {
        staffComplianceRules,
        staffRecurringSchedules,
        notes: notes.filter((note) => note.userId === userId)
      }
      staff = extendFacilityUser(facilityUser, extensionParameters)
    }
    return setStaff(fromJS(staff))
  }

  async function createFacilityUser(parameters) {
    const {
      homeUnitId,
      startsAt,
      preferredFirstName,
      preferredLastName,
      email,
      phoneNumber,
      phoneNumber1,
      phoneNumber2,
      firstName,
      lastName,
      timeZone,
      locale,
      avatarUrl,
      employmentType,
      position,
      maximumNumberOfHoursPerWeek,
      workingHours,
      managerFullName,
      availabilityType
    } = parameters

    const createParameters = {
      parameters: {
        roleIds: ['staff'],
        profile: {
          locale,
          timeZone,
          avatarUrl,
          email: email ? email : null,
          lastName: preferredLastName || lastName,
          firstName: preferredFirstName || firstName,
          phoneNumber: phoneNumber ? phoneNumber : null
        }
      }
    }

    const { createFacilityUser: user } = await gqlClient.mutate(createFacilityUserMutation, createParameters)

    const { userId } = user
    const updateParameters = {
      userId,
      staffProfile: {
        employmentType,
        position,
        availabilityType,
        maximumNumberOfHoursPerWeek: maximumNumberOfHoursPerWeek || 0,
        workingHours,
        phoneNumber1: phoneNumber1 ? phoneNumber1 : null,
        phoneNumber2: phoneNumber2 ? phoneNumber2 : null,
        managerFullName
      }
    }

    const { updateStaffProfile: userUpdated } = await gqlClient.mutate(updateStaffProfileMutation, updateParameters)

    const newEligibleUnitParameters = { userId, eligibleUnitsItem: { homeUnitId, startsAt } }
    await gqlClient.mutate(appendStaffEligibleUnitsItemMutation, newEligibleUnitParameters)

    return fromJS(userUpdated)
  }

  async function cancellStaffTransfer(userId) {
    return gqlClient.mutate(popStaffEligibleUnitsItemMutation, { userId })
  }

  async function initiateStaffTransfer(userId, { homeUnitId, startsAt }) {
    const eligibleUnitsItem = { startsAt, homeUnitId }
    return gqlClient.mutate(appendStaffEligibleUnitsItemMutation, {
      userId,
      eligibleUnitsItem
    })
  }

  async function acceptStaffTransfer(userId, parameters) {
    const { startsAt, homeUnitId, homeUnitRoleId, homeUnitShiftTypeId } = parameters
    const eligibleUnitsItem = {
      startsAt,
      homeUnitId,
      homeUnitRoleId,
      homeUnitShiftTypeId
    }
    await gqlClient.mutate(popStaffEligibleUnitsItemMutation, { userId })
    return gqlClient.mutate(appendStaffEligibleUnitsItemMutation, {
      userId,
      eligibleUnitsItem
    })
  }

  function appendStaffEligibleUnit(userId, { homeUnitId, homeUnitRoleId, homeUnitShiftTypeId, startsAt }) {
    const eligibleUnitsItem = {
      startsAt,
      homeUnitId,
      homeUnitRoleId,
      homeUnitShiftTypeId
    }

    return gqlClient.mutate(appendStaffEligibleUnitsItemMutation, {
      userId,
      eligibleUnitsItem
    })
  }

  async function updateStaffProfileExpertise(params) {
    const { userId, expertiseCredentials } = params
    const staffProfile = {
      expertiseCredentials
    }
    await gqlClient.mutate(updateStaffProfileExpertiseMutation, {
      userId,
      staffProfile
    })
  }
  async function updateFacilityUser(params) {
    const {
      endsAt,
      userId,
      phoneNumber1,
      phoneNumber2,
      firstName,
      lastName,
      employmentType,
      position,
      maximumNumberOfHoursPerWeek,
      workingHours,
      managerFullName,
      email,
      phoneNumber,
      availabilityType,
      expertiseCredentials,
      isStaffOnly = false
    } = params

    const facilityProfile = {
      firstName,
      lastName,
      email: email,
      phoneNumber: phoneNumber
    }

    const staffProfile = {
      employmentType,
      position,
      maximumNumberOfHoursPerWeek,
      workingHours,
      phoneNumber1: phoneNumber1 ? phoneNumber1 : null,
      phoneNumber2: phoneNumber2 ? phoneNumber2 : null,
      managerFullName,
      availabilityType,
      expertiseCredentials
    }

    if (isStaffOnly) {
      await gqlClient.mutate(updateMyFacilityUserFacilityProfileMutation, {
        facilityProfile
      })
    } else {
      await gqlClient.mutate(updateFacilityUserFacilityProfileMutation, {
        userId,
        facilityProfile
      })
      await gqlClient.mutate(updateStaffProfileMutation, {
        userId,
        staffProfile
      })
      if (endsAt) {
        await gqlClient.mutate(terminateStaffMutation, { userId, endsAt })
      }
    }
  }

  function extendFacilityUser(user, parameters) {
    const { notes, staffComplianceRules, staffRecurringSchedules } = parameters

    user = extendFacilityUserWithAvatar(user)
    user.staffProfile.staffComplianceRules = staffComplianceRules || []
    user.staffProfile.staffRecurringSchedules = staffRecurringSchedules || []
    user.notes = notes || []
    return user
  }

  function extendFacilityUserWithAvatar(user) {
    const { profile } = user

    if (profile?.avatarUrl) {
      user.profile.avatarUrl = avatar(instanceUri, profile.avatarUrl)
      user.facilityProfile.avatarUrl = avatar(instanceUri, profile.avatarUrl)
    }

    return user
  }
}

export default Staff()
