import { Component, Fragment } from 'react'
import classNames from 'classnames'
import OpenShift from './OpenShift'
export default class WeekOpenShifts extends Component {
  render() {
    const { openShifts, weekFromNow, unitRoles, toggle, isExpanded } = this.props
    const weekRange = openShifts.first().getIn([0, 'weekRange'])
    const isThisWeek = weekFromNow === 0
    const isNextWeek = weekFromNow === 1

    const keys = openShifts.keySeq()

    const { facilityUsersMap, openShiftsStore, timeService } = this.props
    const openShiftsListProps = {
      openShifts,
      openShiftsStore,
      facilityUsersMap,
      keys,
      unitRoles,
      timeService
    }

    return (
      <section className="hx-week-open-shifts">
        <h5
          className={classNames({
            'bg-catskillwhite upper black': true
          })}
          onClick={toggle}
        >
          {weekRange} ({openShifts.reduce((sum, item) => sum + item.size, 0)})
          <span className="jungle-green">
            {isThisWeek && 'This Week'}
            {isNextWeek && 'Next Week'}
          </span>
          <button className="float-right">
            <i
              className={classNames({
                'float-right regent-gray': true,
                'icon-up big': isExpanded,
                'icon-right': !isExpanded
              })}
            />
          </button>
        </h5>
        {isExpanded && <OpenShiftsList {...openShiftsListProps} />}
      </section>
    )
  }
}

function OpenShiftsList({ openShifts, ...rest }) {
  return openShifts.keySeq().map((key, index) => {
    const [weekDay, day, month] = key.split(' ')
    return (
      <Fragment key={`${key + index}`}>
        {openShifts.get(key).map((openShift, index) => {
          const shiftDayId = openShift.get('shiftDayId')
          return (
            <OpenShift
              weekDay={weekDay}
              day={day}
              month={month}
              compensateCol={index !== 0}
              openShift={openShift}
              key={shiftDayId}
              {...rest}
            />
          )
        })}
      </Fragment>
    )
  })
}
