import React, { useState, ReactNode } from 'react'
import Item from './Item'

interface ItemAccordionProps {
  label: string
  children: ReactNode
}

const ItemAccordion: React.FC<ItemAccordionProps> = ({ label, children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <Item
        isCollapsible={true}
        title={label}
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
        onMouseEnter={() => {}}
        isChecked={false}
      />
      {expanded && children}
    </>
  )
}

export default ItemAccordion
