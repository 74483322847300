import { Component } from 'react'
import { UpdateEntity } from '@humanics/he-react-common/lib/admin/components'
import { pick, cloneDeep } from 'lodash'
import { isFacilityEnabledFeature } from 'utils'
import { t } from 'i18n'

export default class UpdateShift extends Component {
  operationalDetailItems = {
    startTime: {
      label: 'Start Time',
      required: true,
      format: 'time',
      type: 'string',
      inputType: 'InputTime',
      default: '00:00',
      isDisabled: true
    },
    endTime: {
      label: 'End Time',
      required: true,
      format: 'time',
      inputType: 'InputTime',
      default: '00:00',
      isDisabled: true
    },
    targetCover: {
      inputType: 'InputNumberRange',
      items: {
        min: {
          label: 'MIN',
          inputType: 'InputNumber',
          min: 0,
          default: 0,
          isDisabled: true
        },
        max: {
          label: 'MAX',
          inputType: 'InputNumber',
          min: 0,
          default: 0,
          isDisabled: true
        }
      }
    },
    allowableGap: {
      label: 'Allowable Gap',
      inputType: 'TimePicker',
      default: 0,
      isDisabled: true
    }
  }
  constructor(props) {
    super(props)
    this.expertiseFields = ['licenseIds', 'certificationIds', 'skillIds', 'equipmentSkillIds']
    this.expertiseFieldsMap = {
      licenseIds: 'license',
      certificationIds: 'certification',
      skillIds: 'skill',
      equipmentSkillIds: 'equipment'
    }
    this.state = {
      apiInitialized: false,
      expertiseItems: [],
      supportsRemoteEquipments: false,
      remoteEquipments: [],
      isDataFetched: false,
      alreadyLinkedEquipmentIds: new Set()
    }
    this.inputDefinitionExtraFields = {
      unitId: { disabled: true },
      unitRoleId: {
        disabled: true,
        inputType: 'InputDropdownUnitRoles',
        withLabel: true,
        value: (formState) => formState.groupName
      },
      // TODO: revisit later. This doesnt show up correct value in the form
      // typeId: { disabled: true, inputType: 'InputDropdownShiftTypes' },
      isOnCall: {
        disabled: true,
        type: 'boolean'
      },
      licenseIds: {
        label: 'License',
        storeName: 'expertise',
        loadItemsParameters: {
          type: 'license'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'license'),
        items: {
          expertiseId: {
            label: 'License',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          }
        }
      },
      certificationIds: {
        label: 'Credentials/Certifications',
        storeName: 'expertise',
        loadItemsParameters: {
          type: 'certification'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'certification'),
        items: {
          expertiseId: {
            label: 'Credentials/Certifications',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          }
        }
      },
      skillIds: {
        label: 'Skill',
        storeName: 'expertise',
        loadItemsParameters: {
          type: 'skill'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'skill'),
        items: {
          expertiseId: {
            label: 'Select Skill',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          },
          eligibleScaleIndexes: {
            label: 'Minimum Competence',
            inputType: 'ScaleItemsDropdown',
            withLabel: true,
            dependsOn: 'expertiseId'
          }
        }
      },
      equipmentSkillIds: {
        label: 'Equipment Skill',
        loadItemsParameters: {
          type: 'equipment'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'equipment'),
        items: {
          expertiseId: {
            label: 'Equipment + Skill',
            inputType: 'GroupedExpertiseDropdown',
            withLabel: true
          },
          eligibleScaleIndexes: {
            label: 'Minimum Competence',
            inputType: 'ScaleItemsDropdown',
            withLabel: true,
            dependsOn: 'expertiseId'
          }
        }
      },
      isEnabledRemoteService: {
        label: 'Enable Remote Services',
        type: 'boolean',
        isVisible: () => this.props.type === 'equipment',
        onChange: (e) => {
          if (e.target.checked) {
            this.loadRemoteEquipments()
          }
        },
        value: (formState) => formState.remoteDetails?.isEnabledRemoteService || formState.isEnabledRemoteService
      },
      deviceId: {
        label: 'Remote Equipment',
        isDisabled: false,
        inputType: 'InputDropdown',
        withLabel: true,
        required: true,
        propKey: 'deviceId',
        propValue: 'name',
        enum: this.state.remoteEquipments,
        isVisible: (formState) => this.state.supportsRemoteEquipments && formState.isEnabledRemoteService,
        value: (formState) => {
          if (formState.remoteDetails?.isEnabledRemoteService || formState.isEnabledRemoteService) {
            this.loadRemoteEquipments()
          }

          return formState.remoteDetails?.deviceId || formState.deviceId
        },
        isDisabledOption: (data) => this.state.alreadyLinkedEquipmentIds.has(data.deviceId),
        disabledOptionReason: t('equipments.already_linked')
      },
      name: {
        withLabel: true,
        required: true,
        type: 'string',
        isVisible: (formState) => !this.state.supportsRemoteEquipments || !formState.isEnabledRemoteService
      },
      resourceType: {
        value: this.props.type
      },
      defaultDetails: {
        label: 'Default Operational Setting',
        inputType: 'InputOperationalDetail',
        model: 'unitResourceDefaultDetails',
        formItemClasses: 'offset-3',
        items: this.operationalDetailItems
      },
      operationalDetails: {
        label: 'Custom Operational Setting',
        inputType: 'InputOperationalDetails',
        default: {},
        dependsOnFormData: true,
        items: {
          isWorking: {
            type: 'boolean',
            default: false,
            disabled: true
          },
          operationalDetail: {
            label: 'operationalDetail',
            inputType: 'InputOperationalDetail',
            items: this.operationalDetailItems
          }
        }
      }
    }
  }

  async loadRemoteEquipments() {
    if (!this.state.supportsRemoteEquipments || this.state.isDataFetched) {
      return
    }

    const store = this.props.stores.get('shift')
    const remoteEquipmentsList = await store.loadRemoteEquipments()
    const weScanRemoteEquipments = remoteEquipmentsList.map((equipment) => equipment.data.wescan)
    await this.updateAlreadyLinkedEquipmentIds(store)

    this.setState({ remoteEquipments: weScanRemoteEquipments, isFetchingData: false, isDataFetched: true })
  }

  async updateAlreadyLinkedEquipmentIds(store) {
    const equipments = await store.loadShiftsWithResourceType([this.props.type])
    const equipmentIdsSet = new Set()
    const currentEquipmentId = this.props.match.params.id

    for (const equipment of equipments) {
      if (equipment.remoteDetails?.isEnabledRemoteService && equipment.id !== currentEquipmentId) {
        equipmentIdsSet.add(equipment.remoteDetails.deviceId)
      }
    }

    this.setState({ alreadyLinkedEquipmentIds: equipmentIdsSet })
  }

  componentDidMount() {
    const { stores } = this.props

    ;(async () => {
      const store = stores.get('expertise')
      await store.getApi()
      const { stateKey } = store.getAdminInfo()
      store.loadDataAction().then((state) => {
        const expertiseItems = state.getIn([stateKey, 'list'])
        this.setState({ apiInitialized: true, expertiseItems })
      })
    })()

    if (this.props.type !== 'equipment') {
      return
    }

    const doesFacilitySupportRemoteEquipments = isFacilityEnabledFeature(
      this.props.appState,
      'remote_workflow_wescan',
      ['write']
    )

    const message = doesFacilitySupportRemoteEquipments
      ? t('equipments.facility_supports_remote_services')
      : t('equipments.facility_does_not_support_remote_services')

    this.setState({ supportsRemoteEquipments: doesFacilitySupportRemoteEquipments, message })
  }

  getExpertiseValue = (defaultValues, type) => {
    const { expertiseItems } = this.state
    const values = defaultValues['expertiseRequirements'] || []
    const expertiseIds = expertiseItems.filter((item) => item.type === type).map((item) => item.id)
    return values.filter((item) => expertiseIds.includes(item.expertiseId))
  }

  render() {
    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }

    this.updateDefinitionsForEquipmentType()

    const updateEntityProps = {
      ...this.props,
      inputDefinitionExtraFields: this.inputDefinitionExtraFields
    }

    return <UpdateEntity width={900} {...updateEntityProps} onSubmit={this.onSubmit} />
  }

  prepareExpertise = (form) => {
    let expertiseRequirements = []
    this.expertiseFields.forEach((field) => {
      const data = form[field] || []
      const availableFields = Object.keys(this.inputDefinitionExtraFields[field].items)
      const filteredData = data.map((item) => pick(item, availableFields))
      expertiseRequirements = [...expertiseRequirements, ...filteredData]
    })
    return expertiseRequirements
  }

  cleanNonWorkingOperationalDetails = (form) => {
    const updatedOperationalDetails = cloneDeep(form.operationalDetails)
    for (const day in updatedOperationalDetails) {
      if (!updatedOperationalDetails[day].isWorking) {
        updatedOperationalDetails[day].operationalDetail = undefined
      }
    }
    return updatedOperationalDetails
  }

  onSubmit = (e, form) => {
    const { store, history, match, updateOperationId, type } = this.props
    const { ...rest } = form
    const expertiseRequirements = this.prepareExpertise(form)
    const operationalDetails = this.cleanNonWorkingOperationalDetails(form)

    const { targetCover, allowableGap } = form.defaultDetails

    const params = {
      ...rest,
      expertiseRequirements,
      targetCover,
      allowableGap,
      operationalDetails
    }

    if (params.isEnabledRemoteService) {
      params.remoteDetails = {
        isEnabledRemoteService: params.isEnabledRemoteService,
        deviceId: params.deviceId
      }
    }

    delete params.isEnabledRemoteService
    delete params.deviceId

    const { id } = match.params

    return store
      .update({ updateOperationId, form: params }, id, { resourceTypes: [type] })
      .then(() => history.push('.'))
  }

  updateDefinitionsForEquipmentType() {
    if (this.props.type !== 'equipment') {
      return
    }

    this.inputDefinitionExtraFields.isEnabledRemoteService.disabled = !this.state.supportsRemoteEquipments
    this.inputDefinitionExtraFields.isEnabledRemoteService.expectedFormat = (
      <sh-icon
        icon="information"
        color="#808fa4"
        size="xs"
        title={this.state.message}
        style={{ display: 'inline-block', top: '-2px' }}
      ></sh-icon>
    )

    if (this.state.supportsRemoteEquipments) {
      this.inputDefinitionExtraFields.deviceId.enum = this.state.remoteEquipments
    }
  }
}
